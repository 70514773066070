import { ExtendedTheme } from "@sightworks/theme";

export default (theme: ExtendedTheme) => ({
	root: {
		margin: theme.spacing(1),
		flex: 1,
		minHeight: 200,
	},
	cardContent: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
	},
	content: {
		fontWeight: 'bold',
	},
});
