import React, { Component, useState, useEffect, useRef } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '../../components/Typography';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import axios from 'axios';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Fab from '@material-ui/core/Fab';
import GoogleResultsProps from './props';
import { createMuiTheme } from '@material-ui/core';

const GoogleResultsBox = ({ classes, googleKey, googleId }: GoogleResultsProps, ref: React.Ref<any>) => {
	const [results, setResults] = useState([]);
	const inputEl = useRef<HTMLInputElement>();
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [invalidQuery, setInvalidQuery] = useState(null);
	const [nextPageStart, setNextPageStart] = useState<number>(0);
	const [more, setMore] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const numOfResultsToReturn = 10;

	const handleGoogleSearch = async (query: string, page: number) => {
		const response = await axios.get(
			`https://www.googleapis.com/customsearch/v1?key=${googleKey}&cx=${googleId}&q=${query}&start=${page}&num=${numOfResultsToReturn}&filter=1`
		);

		const nextPage = response.data.queries.nextPage ? response.data.queries.nextPage[0].startIndex : null;

		if (nextPage && nextPage < 101) {
			setNextPageStart(nextPage);
			setMore(true);
		} else setMore(false);

		return { items: response.data.items };
	};

	useEffect(() => {
		const url = location.toString();
		const urlObject = new URL(url);
		const query: string = urlObject.searchParams.get('q');
		setSearchQuery(query);
		const fetchResults = async () => {
			try {
				const { items } = await handleGoogleSearch(query, 1);
				if (items) setResults(items);
				else {
					setResults([]);
					setInvalidQuery(query);
				}
			} catch (error) {
				console.error(error);
			}
		};
		if (query) fetchResults();
		setLoading(false);
	}, []);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		console.log(invalidQuery);
		setInvalidQuery(null);
		setLoading(true);
		const query = inputEl.current.value;
		history.replaceState({ query }, '', `search?q=${query}`);
		setSearchQuery(query);

		if (query.length > 0) {
			try {
				const { items } = await handleGoogleSearch(query, 1);
				if (items) {
					setResults(items);
				} else {
					setResults([]);
					setInvalidQuery(query);
				}
			} catch (error) {
				console.error(error);
			}
		}
		setLoading(false);
	};

	const handleLoadMore = async () => {
		setLoading(true);
		const { items } = await handleGoogleSearch(searchQuery, nextPageStart);
		if (items) setResults([...results, ...items]);
		setLoading(false);
	};

	return (
		<div className={classes.root}>
			<form onSubmit={handleSubmit}>
				<FormControl className={classes.searchBox} variant="outlined" fullWidth>
					<InputLabel htmlFor="google-search-box">Search</InputLabel>
					<OutlinedInput
						id="google-search-box"
						endAdornment={
							<InputAdornment position="end">
								<IconButton aria-label="search" edge="end" color="primary" type="submit">
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						}
						inputRef={inputEl}
						labelWidth={70}
						value={searchQuery || ''}
						onChange={e => setSearchQuery(e.target.value)}
					/>
				</FormControl>
			</form>
			{results.map(result => {
				// const imgSrc = result.pagemap.cse_thumbnail ? result.pagemap.cse_thumbnail[0].src : null;
				return (
					<div key={result.cacheId + result.link}>
						<Grid container>
							<Grid item xs={12}>
								<div className={classes.details}>
									<CardContent className={classes.content}>
										<Link href={result.link} variant="h6" className={classes.title}>
											{result.title}
										</Link>
										<Link href={result.link} variant="caption" color="textSecondary" className={classes.link}>
											{result.link}
										</Link>
										<Typography variant="body1" color="textSecondary">
											{result.snippet}
										</Typography>
									</CardContent>
								</div>
							</Grid>
						</Grid>
					</div>
				);
			})}
			{invalidQuery && (
				<Grid container>
					<Grid item xs={12}>
						<div className={classes.details}>
							<CardContent className={classes.content}>
								{/* <Link href={result.link} variant="h6" className={classes.title}>
										Your search - testtesttest - did not match any documents.
									</Link> */}
								{/* <Link href={result.link} variant="caption" color="textSecondary" className={classes.link}>
									{result.link}
								</Link> */}
								<Typography variant="body1" color="textSecondary">
									Your search - <span className={classes.queryInvalid}>{invalidQuery}</span> - did not match
									any documents.
								</Typography>
							</CardContent>
						</div>
					</Grid>
				</Grid>
			)}
			{loading && (
				<Grid container>
					<Grid item xs={12}>
						<div className={classes.details}>
							<CardContent className={classes.content}>
								<Skeleton height={10} style={{ marginBottom: 6 }} />
								<Skeleton height={10} width="80%" />
							</CardContent>
						</div>
					</Grid>
				</Grid>
			)}
			{more && (
				<div className={classes.flexContainer}>
					<Fab
						variant="extended"
						color="primary"
						aria-label="add"
						className={classes.loadMore}
						onClick={handleLoadMore}
						disabled={loading}
					>
						Load More
					</Fab>
				</div>
			)}
		</div>
	);
};

export default ThemeLoader(
	StyleLoader(
		GoogleResultsBox,
		makeStyles<ReturnType<typeof createMuiTheme>>(
			theme => ({
				root: {
					minHeight: '600px',
				},
				searchBox: {
					marginTop: theme.spacing(4),
					marginBottom: theme.spacing(4),
				},
				card: {
					display: 'flex',
					marginTop: theme.spacing(1),
					marginBottom: theme.spacing(1),
				},
				details: {
					display: 'flex',
					flexDirection: 'column',
				},
				content: {
					flex: '1 0 auto',
				},
				cover: {
					width: '100%',
					height: '100%',
				},
				controls: {
					display: 'flex',
					alignItems: 'center',
					paddingLeft: theme.spacing(1),
					paddingBottom: theme.spacing(1),
				},
				playIcon: {
					height: 38,
					width: 38,
				},
				title: {
					cursor: 'pointer',
					display: 'block',
				},
				flexContainer: {
					display: 'flex',
					width: '100%',
					justifyContent: 'center',
				},
				loadMore: {
					marginBottom: theme.spacing(4),
					paddingLeft: theme.spacing(4),
					paddingRight: theme.spacing(4),
				},
				addIcon: {
					marginRight: theme.spacing(1),
				},
				skeleton: {},
				link: {
					wordWrap: 'break-word',
				},
				queryInvalid: {
					fontWeight: 'bold',
				},
			}),
			{ name: 'SWGoogleResults' }
		)
	)
);
