import Block from './block';
import ArticleCallOutProps from './props';
import { BlockPropsBase } from '../../props';
import { BlockType } from '../../block-type';
let description: typeof import('./description').default;

import type BlockRecord from '../../record';
let subLabel: (record: BlockRecord) => Promise<string>;

let getProps: typeof import('./getProps').default;

if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
	description = require('./description').default;
	subLabel = async (record: BlockRecord) => record.variant;
}
if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
}

const ArticleCallOut: BlockType = {
	component: Block,
	getChildren(props: ArticleCallOutProps): BlockPropsBase[] { return []; },
	description,
	subLabel,
	section: 'Article',
	info: {
		requiresParent: null,
		requiresAncestor: [ 'article/root' ],
		permittedChildren: [],
		forbiddenAncestor: [],
		forbiddenDescendant: []
	},
	getProps,
	cacheRequiresRecord: () => false
};

export default ArticleCallOut;

