import field, { Style } from './field';
import type TextAreaQuestionRecord from './question';
import type { ContentType } from '@sightworks/datastore';

let Question: typeof import('./question').default;
if (process.env.BROWSER != 'true') {
	Question = require('./question').default;
}

let description: typeof import('./description').default;
let title: (record: TextAreaQuestionRecord) => Promise<string>;
let addAnswerField: (type: ContentType) => void;
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
	title = async (record: TextAreaQuestionRecord): Promise<string> => `Text Field (multi-line): ${record.title}`;
	addAnswerField = (type: ContentType) => {
		type.addText('Answer');
	}
}


export default {
	field,
	style: Style,
	description,
	title,
	isAnswerable: true,
	addAnswerField,
	question: Question,
}

