import QueryProvider from '../../utils/query';
import React from 'react';
import FilterProps from './props';
import getChildren from '../../utils/children';
import FilterContext from './context';

export default (props: FilterProps): JSX.Element => {
    return <QueryProvider query={props.queryContent}>
        <FilterContext.Provider value={props.prefix}>{
            getChildren(props.content)
        }</FilterContext.Provider>
    </QueryProvider>
}