import React from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import Box from '../../containers/box/block';
import { BlockPropsBase } from '../..';

const SummaryBlock = (props: BlockPropsBase, ref: React.Ref<any>) => {
	return <Box {...props} ref={ref} />;
};

export default ThemeLoader(StyleLoader(SummaryBlock));
