import field, { Style } from './field';
import type HeaderQuestionRecord from './question';

let Question: typeof import('./question').default;
if (process.env.BROWSER != 'true') {
	Question = require('./question').default;
}

let description: typeof import('./description').default;
let title: (record: HeaderQuestionRecord) => Promise<string>;
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
	title = async (record: HeaderQuestionRecord): Promise<string> => `Header: ${record.text}`;
}

export default {
	field,
	style: Style,
	description,
	title,
	isAnswerable: false,

	question: Question
}
