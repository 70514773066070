import React, { Component, useState, useEffect } from 'react';
import getChildren from '../../utils/children';
import Lightbox from './context';
import GalleryProps from './props';

const GalleryContainerBlock = (props: GalleryProps, ref: React.Ref<any>) => {
	const { content } = props;
	return <Lightbox {...props}>{getChildren(content)}</Lightbox>;
};

export default GalleryContainerBlock;
