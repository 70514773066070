import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import React, { useCallback } from 'react';
import ChecklistItemBody from './body';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useBreakpoint } from '@sightworks/block';

const ChecklistItemField = ({
    dispatch,
    id,
    fieldId,
    value,
    error,
    title, 
    itemType,
    classes,
}: ChecklistItemBody, ref: React.Ref<any>) => {
    let toggle = useCallback(() => {
        let varr = String(value).split(',');
        if (itemType == 'checklist') {
            if (varr.includes(id)) varr.splice(varr.indexOf(id), 1);
            else varr.push(id);
        } else {
            varr = [id];
        }
        dispatch({ action: 'setField', data: { [fieldId]: varr.join(',') } });
    }, [value, id]);
    let bp = useBreakpoint();

    let varr = String(value).split(',');
    if (itemType == 'dropdown') {
        return React.createElement(bp == 'xs' ? 'option' : MenuItem, {
            key: id,
            value: id,
            ref
        }, title);
    }

    return <FormControlLabel ref={ref}
            control={React.createElement(itemType == 'radio' ? Radio : Checkbox, {
                checked: varr.includes(id),
                onChange: toggle,
                name: id
            })}
            label={title}/>
}

export default React.forwardRef(ChecklistItemField);
export const Style = {};
