import HeaderBanner from './header-banner';
import { ExtendedTheme } from '@sightworks/theme';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { CSSProperties } from '@material-ui/styles';

export default (theme: ExtendedTheme) => {
	const styles = HeaderBanner(theme);

	(['xs', 'sm', 'md', 'lg', 'xl'] as Breakpoint[]).forEach(v => {
		const b = theme.breakpoints.only(v);
		let x: any = styles.root[b];
		x.minHeight *= 0.6;
	});
	(styles.root[theme.breakpoints.only('xl')] as any)['& .MuiTypography-root, & [class*=MuiTypography-root-]'].maxWidth = 1600;

	return styles;
};
