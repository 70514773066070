import React, { useMemo, useCallback, createContext, useReducer } from 'react';
import PaginatorProps from './props';

export type PaginatorContextType = {
	page: number;
	pageCount: number;
	itemCount: number;
	itemsPerPage: number; 

	dispatch: React.Dispatch<PaginatorContextAction>,
	nextPage: () => void,
	previousPage: () => void,
	setPage(page: number): void;
	setItemsPerPage(itemsPerPage: number): void;
	setItemCount(itemCount: number): void;

	state: PaginatorState
};

export type PaginatorState = {
	page: number;
	pageCount: number;
	itemCount: number;
	itemsPerPage: number;
}

const PaginatorContext = createContext<PaginatorContextType>(null);
export { PaginatorContext as Context };

export type PaginatorContextAction = PaginatorNext | PaginatorPrev | PaginatorGo | PaginatorItemCount | PaginatorPageCount | PaginatorInit ;
type PaginatorNext = { type: 'next' };
type PaginatorPrev = { type: 'prev' };
type PaginatorGo = { type: 'go', page: number };
type PaginatorItemCount = { type: 'count', itemCount: number };
type PaginatorPageCount = { type: 'pageCount', itemsPerPage: number };
type PaginatorInit = { type: 'init', itemsPerPage: number }

function paginatorReducer(state: PaginatorState, action: PaginatorContextAction): PaginatorState {
	// console.log({ type, action });
	
	switch (action.type) {
		case 'next':
			return { ...state, page: Math.min(state.pageCount - 1, state.page + 1) }
		case 'prev':
			return { ...state, page: Math.max(0, state.page - 1) }
		case 'go':
			return { ...state, page: Math.max(0, Math.min(state.pageCount - 1, action.page)) }
		case 'count':
			let pageCount = Math.max(1, Math.ceil(action.itemCount / state.itemsPerPage));

			return {
				...state,
				page: pageCount > state.page ? state.page : pageCount - 1,
				pageCount,
				itemCount: action.itemCount
			}
		case 'pageCount':
			let currentPageOffset = state.itemsPerPage * state.page;
			let newPageOffset = Math.floor(currentPageOffset / action.itemsPerPage);
			let newPageCount = Math.max(1, Math.ceil(state.itemCount / action.itemsPerPage));
			return {
				...state,
				page: newPageOffset,
				pageCount: newPageCount,
				itemsPerPage: action.itemsPerPage
			}

			default:
			console.log(`Invalid action ${(action as any).type}:`, action);
			return state;
		case 'init':
			return {
				page: 0,
				pageCount: 1,
				itemCount: 0,
				itemsPerPage: action.itemsPerPage
			}
	}
}

const PaginatorContainer = (props: React.PropsWithChildren<PaginatorProps>) => {
	const [ state, dispatch ] = useReducer(paginatorReducer, {}, () => paginatorReducer(void 0, { type: 'init', itemsPerPage: props.itemsPerPage }));
	const paginatorContext = useMemo<PaginatorContextType>(() => ({
		page: state.page,
		pageCount: state.pageCount,
		itemCount: state.itemCount,
		itemsPerPage: state.itemsPerPage,
		dispatch,
		nextPage() {
			dispatch({ type: 'next' })
		},
		previousPage() {
			dispatch({ type: 'prev' })
		},
		setPage(page: number) {
			dispatch({ type: 'go', page })
		},
		setItemsPerPage(itemsPerPage: number) {
			dispatch({ type: 'pageCount', itemsPerPage })
		},
		setItemCount(itemCount: number) {
			dispatch({ type: 'count', itemCount })
		},
		state
	}), [state.pageCount, state.itemCount, state.itemsPerPage, state.page]);

	return (
		<PaginatorContext.Provider value={paginatorContext}>
			{props.children}
		</PaginatorContext.Provider>
	);
};

export default PaginatorContainer;
