import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';

type ActionContextType = {
	target: any;
	setTarget(target: any): void;
}

const ActionContext = createContext<ActionContextType>(null);

const Provider = ({ children }: React.PropsWithChildren<{}>) => {
	const [target, setTarget] = useState(null);
	return <ActionContext.Provider value={{ target, setTarget }}>{children}</ActionContext.Provider>;
};

export { ActionContext as Context };
export default Provider;
