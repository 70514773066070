import { BlockType } from '../block-type';

const Items: Record<string, () => BlockType> = {
	'article/base-item': () => require('./base-item/index').default,
	'article/button': () => require('./button/index').default,
	'article/call-out': () => require('./call-out/index').default,
	'article/credits': () => require('./credits/index').default,
	'article/download': () => require('./download/index').default,
	'article/header': () => require('./header/index').default,
	'article/image': () => require('./image/index').default,
	'article/list': () => require('./list/index').default,
	'article/quote': () => require('./quote/index').default,
	'article/text': () => require('./text/index').default,
	'article/video': () => require('./video/index').default,
	'article/root': () => require('./root/index').default,
	'article/tabs': () => require('./tabs/index').default,
	'article/tab': () => require('./tab/index').default
}

export default Items;