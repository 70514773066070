import { BlockType } from '../block-type';
const Items: Record<string, () => BlockType> = {
	'flex-grid': () => require('./flex-grid/index').default,
	'root': () => require('./root/index').default,
	'box': () => require('./box/index').default,
	'sizer-box': () => require('./sizer-box/index').default,
	'carousel': () => require('./carousel/index').default,
	'shared-block-root': () => require('./shared-block-root/index').default,
	'app-bar': () => require('./app-bar/index').default,
	'toolbar': () => require('./toolbar/index').default,
	'drawer': () => require('./drawer/index').default,
	'nav-drawer': () => require('./nav-drawer/index').default,
	'nav-section': () => require('./nav-section/index').default,
	'site-navigation': () => require('./site-navigation/index').default,
	'nav-item': () => require('./nav-item/index').default,
	'hidden': () => require('./hidden/index').default,
	'calendar': () => require('./calendar/index').default,
	'collapse': () => require('./collapse/index').default,
	'record-header': () => require('./record-header/index').default,
	'dialog': () => require('./dialog/index').default,
	'transition': () => require('./transition/index').default,
	'button-container': () => require('./button-container/index').default,
	'content': () => require('./content/index').default,
	'rotated-box': () => require('./rotated-box/index').default,
	'rotated-box-context': () => require('./rotated-box-context/index').default,
	'plaintext-container': () => require('./plaintext-container/index').default,
	'sw-carousel': () => require('./sw-carousel/index').default
}

export default Items;

