import { BlockPropsBase } from "../..";

type FilterSelectItemProps = BlockPropsBase & {
    name: string;
    value: string;
}

export function isFilterSelectItem(item: BlockPropsBase): item is FilterSelectItemProps {
    return item.type == 'filter-select-item';
}

export default FilterSelectItemProps;