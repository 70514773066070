import { BlockType } from '../block-type';

const Items: Record<string, () => BlockType> = {
	freeform: () => require('./freeform/index').default,
	icon: () => require('./icon/index').default,
	image: () => require('./image/index').default,
	plaintext: () => require('./plaintext/index').default,
	embed: () => require('./embed/index').default,
	avatar: () => require('./avatar/index').default
};

export default Items;
