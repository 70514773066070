import { inspect } from 'util';

let Apply = Reflect.apply;
if (!Apply) {
    Apply = (target: Function, receiver: any, args: any[]): any => {
        if (args.length === 1) return target.call(receiver, args[0]);
        if (args.length === 0) return target.call(receiver);
        if (args.length === 2) return target.call(receiver, args[0], args[1]);
        return target.apply(receiver, ...args);
    }
}
type Listener<T> = ((this: T, ...args: any[]) => void) | OnceListener<T>
type ListenerInfo<T> = Listener<T>;
type OnceListener<T> = {
    (this: T, ...args: any[]): void;
    isOnce: true;
    target: Listener<T>;
    fired: boolean;
}

function Once<T extends EventEmitter>(event: string | symbol, handler: Listener<T>, target: T): OnceListener<T> {
    let f: OnceListener<T>;
    f = Object.assign(
        function(...args: any[]) {
            if (f.fired) return;
            f.fired = true;
            Apply(handler, target, args);
            process.nextTick(() => {
                target.off(event, handler);
            })
        },
        {
            isOnce: true,
            target: handler,
            fired: false
        } as OnceListener<T>
    );
    return f;
}

function clone<T>(arr: T[]): T[] {
    let R = new Array(arr.length);
    for (let i = 0, l = arr.length; i < l; ++i) R[i] = arr[i];
    return R;
}

export default class EventEmitter implements NodeJS.EventEmitter {
    constructor() {
        void(0); // Place to put a breakpoint
    }
    private __events: Map<string | symbol, Listener<this>[]> = new Map();

    private _hasNewListener: boolean = false;
    private _hasRemoveListener: boolean = false;
    private _onceCount: number = 0;
    private _emitting: number = 0;

    addListener = this.on;
    removeListener = this.off;

    on(event: string | symbol, listener: Listener<this>): this {
        if (this._hasNewListener) 
            this.emit('newListener', event, listener)
        let x: ListenerInfo<this>[];
        x = this.__events.get(event) || this.__events.set(event, []).get(event);
        if (this._emitting) this.__events.set(event, x = clone(x));
        x[x.length] = listener;
        if ('isOnce' in listener) this._onceCount++;
        if (event === 'newListener') this._hasNewListener = true;
        if (event === 'removeListener') this._hasRemoveListener = true;
        return this;
    }

    once(event: string | symbol, listener: Listener<this>): this {
        return this.on(event, Once(event, listener, this));
    }

    off(event: string | symbol, l: Listener<this>): this {
        let s = this.__events.get(event);
        if (s) {
            let p = s.findIndex(v => v === l || 'isOnce' in v && v.target === l);
            if (p === -1) return this;
            if (this._emitting) {
                s = clone(s);
                this.__events.set(event, s)
            }
            let d = s[p];

            if (s.length === 1) {
                this.__events.delete(event);
            } else {
                s.splice(p, 1);
            }
            if ('isOnce' in d) {
                this._onceCount--;
                if (this._onceCount < 0) {
                    console.trace(`EventEmitter::_onceCount < 0?`);
                }
            }
            if (this._hasRemoveListener) {
                this.emit('removeListener', event, l);
            }
            if (s.length === 1) {
                if (event === 'newListener') this._hasNewListener = false;
                if (event === 'removeListener') this._hasRemoveListener = false;
            }
        }
        return this;
    }

    removeAllListeners(event?: string | symbol): this {
        if (event) {
            let items;
            if ((this._hasRemoveListener || this._onceCount) && (items = this.__events.get(event))) {
                items = clone(items);
                for (let i = 0, l = items.length; i < l; ++i) {
                    this.off(event, items[i]);
                }
            } else {
                this.__events.delete(event);
            }
            if (event === 'newListener') this._hasNewListener = false;
            if (event === 'removeListener') this._hasRemoveListener = false;
      } else {
            if (this._hasRemoveListener || this._onceCount) {
                let all = this.__events.entries();
                for (let item = all.next(); !item.done; item = all.next()) {
                    let [ event, list ] = item.value;
                    if (event == 'removeListener') continue;
                    list = clone(list);
                    for (let i = 0, l = list.length; i < l; ++i) {
                        this.off(event, list[i][0]);
                    }
                }
            } else {
               this.__events = new Map();
            }
            this._hasNewListener = false;
            this._hasRemoveListener = false;
        }
        return this;
    }

    setMaxListeners(n: number): this {
        return this;
    }

    getMaxListeners(): number {
        return Infinity;
    }

    rawListeners(event: string | symbol): Listener<this>[] {
        let p = this.__events.get(event);
        if (p) {
            return clone(p);
        }
        return [];
    }

    listeners(event: string | symbol): Listener<this>[] {
        return this.__events.get(event).map(v => 'isOnce' in v ? v.target : v);
    }

    emit(event: string | symbol, ...args: any[]): boolean {
        // console.log({ event, args });

        let p = this.__events.get(event);

        if (event === 'error') {
            if (!p) {
                if (args[0] && args[0] instanceof Error) {
                    throw args[0]; // Unhandled 'error' event
                }
                let err = new Error(`Unhandled error${args[0] ? ` (${args[0].message})` : ''})`);
                (err as any).context = args[0];
                throw err;
            }
        }
        if (p) {
            this._emitting++;
            // p = clone(p);
            for (let i = 0, l = p.length; i < l; ++i) {
                this.Apply(p[i], this, args);
            }
            this._emitting--;
            return true;
        }
        return false;
    }

    protected Apply = Apply;

    listenerCount(event: string | symbol) {
        return this.__events.get(event)?.length || 0;
    }
    prependListener(event: string | symbol, listener: Listener<this>): this {
        if (this._hasNewListener) {
            this.emit('newListener', event, listener);
        }
        (this.__events.get(event) || this.__events.set(event, []).get(event)).unshift(listener);
        if ('isOnce' in listener) this._onceCount++;
        if (event === 'newListener') this._hasNewListener = true;
        if (event === 'removeListener') this._hasRemoveListener = true;
        return this;
    }
    prependOnceListener(event: string | symbol, listener: Listener<this>): this {
        return this.prependListener(event, Once(event, listener, this));
    }

    eventNames(): Array<string | symbol> {
        return [ ...this.__events.keys() ];
    }
}

const DebugEventEmitter = EventEmitter;

export const DebugEmitter = (debugValues: (keyof EventEmitter)[]): (new () => EventEmitter) => {
    return DebugEventEmitter;
}
export { EventEmitter, DebugEventEmitter };
