import component from './block';
import type { BlockType, Block, BlockPropsBase } from '@sightworks/block';
import { flattenChildren } from '@sightworks/block';
import FormProps from './props';

let description: typeof import('./description').default;
let getProps: typeof import('./getProps').default;
let appendNode: typeof import('@sightworks/block').appendNode;

if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
	description = require('./description').default;
}

if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
	appendNode = require('@sightworks/block').appendNode;
}
let T: BlockType = {
	component,
	getChildren: (record: FormProps): BlockPropsBase[] => {
		let c = flattenChildren(record.content);
		return [
			...flattenChildren(record.button),
			...(c.length ? c : flattenChildren(record.altContent)),
			...flattenChildren(record.instructions)
		];
	},
	description,
	section: 'Content',
	info: {
		requiresParent: null,
		permittedChildren: null,
		forbiddenAncestor: ['form'],
		forbiddenDescendant: ['form']
	},
	getProps,
	appendNode,
	cacheRequiresRecord: () => true
}

export default T;

