import HeaderBanner from './header-banner';
import { ExtendedTheme } from '@sightworks/theme';

export default (theme: ExtendedTheme) => {
	const s = HeaderBanner(theme);
	s.root[theme.breakpoints.down('sm')] = {
		'--background-overlay': 'linear-gradient(to bottom, rgba(255, 255, 255, .6), rgba(255, 255, 255, .6))'
	};
	return s;
};
