import { ExtendedTheme } from "@sightworks/theme";

export default (theme: ExtendedTheme) => ({
	root: {
		margin: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
});
