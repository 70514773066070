import { ThemeOptions, Theme, createMuiTheme as baseCreateMuiTheme, responsiveFontSizes as baseResponsiveFontSizes } from '@material-ui/core/styles';
import { ExtendedTheme, ExtendedThemeOptions } from './types';

let isExtendedTheme = (theme: ThemeOptions): theme is ExtendedThemeOptions => {
	return theme && ('link' in theme || 'fonts' in theme || 'googleFonts' in theme || 'globalStyles' in theme || 'background' in theme);
}

let createMuiTheme = (theme: ThemeOptions): ExtendedTheme => {
	let target: ExtendedTheme = baseCreateMuiTheme(theme);
	
	target.link = {
		color: target.palette.primary.main,
		hover: target.palette.primary[target.palette.type],
		...(target.link || {})
	}
	if (isExtendedTheme(theme)) {
		target.link = {
			color: theme.link?.color ?? target.palette.primary.main,
			hover: theme.link?.hover ?? target.palette.primary[target.palette.type]
		};
		target.fonts = [].concat(target.fonts || [], theme.fonts || []);
		target.googleFonts = [].concat(target.googleFonts || [], theme.googleFonts || []);
		target.background = theme.background ?? target.background;
	}
	return target;
}

let responsiveFontSizes: (theme: ExtendedTheme) => ExtendedTheme = baseResponsiveFontSizes;

export default createMuiTheme;
export { responsiveFontSizes };
