import React, { useContext, useCallback } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import getChildren from '../../utils/children';
import { useLinkAttributes } from '../../utils/linkContext';
import clsx from 'clsx';
import SWButtonProps from './props';

const ButtonBlock = ({ iconButton, button, link, classes, content }: SWButtonProps, ref: React.Ref<any>) => {
	const lp = useLinkAttributes(link);
	const Which = iconButton ? IconButton : Button;
	let { buttonRoot, buttonLabel, ...tc } = classes;
	tc.root = clsx(tc.root, { [buttonRoot]: iconButton });
	tc.label = clsx(tc.label, { [buttonLabel]: iconButton });
	return (
		<Button ref={ref} {...button} {...lp} classes={tc}>
			{getChildren(content)}
		</Button>
	);
};

export default ThemeLoader(
	StyleLoader(
		ButtonBlock,
		makeStyles(
			theme => ({
				root: {},
				label: {},
				buttonRoot: {
					textTransform: 'none',
				},
				buttonLabel: {
					'& > [class*=MuiIcon-root]:first-child': {
						marginLeft: theme.spacing(-1),
						marginRight: theme.spacing(1),
					},
					'& > [class*=MuiIcon-root]:last-child': {
						marginLeft: theme.spacing(1),
						marginRight: theme.spacing(-1),
					},
				},
			}),
			{ name: 'SWButton' }
		)
	)
);
