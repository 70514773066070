import Block from './block';
import { BlockPropsBase, BlockType } from '@sightworks/block';
import AddEventProps from './props';
import { flattenChildren } from '../..';

let getProps: typeof import('./getProps').default;
let description: typeof import('./description').default;

if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
}
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
}

const AddEventBlock: BlockType = {
	component: Block,
	getChildren(props: AddEventProps): BlockPropsBase[] {
        return flattenChildren(props.content);
	},
	description,
	section: 'Specialty',
	info: {
		requiresParent: null,
		requiresAncestor: null,
		permittedChildren: null,
		forbiddenAncestor: [],
		forbiddenDescendant: []
	},
	getProps,
	cacheRequiresRecord: () => false,
};
export default AddEventBlock;

