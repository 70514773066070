import MuiDialogContent, { DialogContentProps } from '@material-ui/core/DialogContent';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const DialogContent = withStyles(theme => ({
	root: {
		padding: theme.spacing(4)
	}
}))((props: DialogContentProps) => <MuiDialogContent dividers {...props}/>);

export default DialogContent;

