import React, { createContext, useMemo, useState, useContext, useCallback, useEffect } from "react";

export interface PauseState {
    get state(): boolean;
    toggle(value?: boolean): boolean;
    useChild(child: PauseState): boolean;
}

const UninitializedPauseState: PauseState = Object.freeze({
    state: false,
    toggle(value: boolean = !this.state): boolean { return false; },
    useChild(child: PauseState): boolean { return false; }
})

export const PauseContext = createContext<PauseState>(UninitializedPauseState);

export default function PauseWrapper(props: React.PropsWithChildren<{}>): JSX.Element {
    const parentContext: PauseState = useContext(PauseContext);
    const [child, setChild] = useState<PauseState>(null);
    const [paused, setPaused] = useState<boolean>(false);
    const togglePaused = useCallback((value: boolean = !paused): boolean => {
        setPaused(value);
        return child ? true : value;
    }, [paused, child]);
    const toggleChild = useCallback((tgt: PauseState): boolean => {
        setChild(tgt);
        if (!tgt) return paused;
        return true;
    }, [paused]);

    const state = useMemo(() => (child || paused) ? true : false, [child, paused]);
    const target: PauseState = useMemo(() => ({
        state,
        toggle: togglePaused,
        useChild: toggleChild
    }), [state]);

    useEffect(() => {
        parentContext.useChild(state ? target : null)
    }, [state])

    return <PauseContext.Provider value={target}>{props.children}</PauseContext.Provider>
}