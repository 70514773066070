import { makeStyles } from '@material-ui/core/styles';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import clsx from 'clsx';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import React, { useState, useCallback, useMemo } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import getChildren from '../../utils/children';
import ArticleTabsProps from './props';
import ArticleTabProps from '../tab/props';
import { BlockPropsBase, flattenChildren } from '../..';

function filterClasses(classes: Record<string, string>, prefix: string): Record<string, string> {
	let output: Record<string, string> = {};
	for (let name in classes) {
		if (name.indexOf(prefix) == 0) {
			let v = name.substring(prefix.length);
			if (!v) continue;
			let vv = v.charAt(0);
			if (vv.toLowerCase() == vv) continue;
			
			v = v.charAt(0).toLowerCase() + v.substring(1);
			output[v] = classes[name];
		}
	}
	return output;
}

let isTab = (props: BlockPropsBase): props is ArticleTabProps => {
	return props.type == 'article/tab';
}

const TabPanelBlock = (props: ArticleTabsProps, ref: React.Ref<any>) => {
	let [ tab, setTab ] = useState(0);
	let handleChange = useCallback((event, value) => setTab(value), []);
	let [ appBarClasses, tabsClasses, tabClasses ] = useMemo(() => ([
		filterClasses(props.classes, 'appBar'),
		filterClasses(props.classes, 'tabs'),
		filterClasses(props.classes, 'tab')
	]), [props.classes]);
	let theme = useTheme();
	if (props.centered) tabsClasses.root = clsx(tabsClasses.root, tabsClasses.centered);
	let v: ArticleTabProps[] = flattenChildren(props.content).map(v => isTab(v) ? v : null).filter(v => !!v);

	let axis: 'x' | 'x-reverse' | 'y' = props.orientation == 'horizontal' ? (theme.direction == 'rtl' ? 'x-reverse' : 'x') : 'y';
	
	return (
		<div className={props.classes.root}>
			<AppBar position="static" color="transparent" classes={appBarClasses}>
				<Tabs value={tab} onChange={handleChange} variant="scrollable" centered={props.centered} indicatorColor={props.indicatorColor} textColor={props.textColor} orientation={props.orientation} classes={tabsClasses}>
					{v.map((tab: ArticleTabProps, index) => (
						<Tab value={index} classes={tabClasses} label={tab.title} key={index} id={`${tab.id}-tab`} aria-controls={`${tab.id}-panel`} />
					))}
				</Tabs>
			</AppBar>
			<SwipeableViews axis={axis} index={tab} onChangeIndex={setTab}>
				{
					getChildren(v, (node, child, index) => (
						<Box role="tabpanel" hidden={tab != index} id={`${child.id}-panel`} aria-labelledby={`${child.id}-tab`} key={child.id}>
							{node}
						</Box>
					))
				}
			</SwipeableViews>
		</div>
	);
}

const Styles = makeStyles(theme => ({
	root: {},
	appBarRoot: {},
	appBarPositionStatic: {},
	appBarColorTransparent: {},
	tabsRoot: {},
	tabsVertical: {},
	tabsFlexContainer: {},
	tabsFlexContainerVertical: {},
	tabsCentered: {},
	tabsScroller: {},
	tabsFixed: {},
	tabsScrollable: {},
	tabsScrollButtons: {},
	tabsScrollButtonsDesktop: {},
	tabsIndicator: {},
	tabDisabled: {},
	tabFullWidth: {},
	tabLabelIcon: {},
	tabRoot: {
		'$tabsCentered &': {
			'&:first-child': { marginLeft: 'auto' },
			'&:last-child': { marginRight: 'auto' }
		}
	},
	tabSelected: {
		'& $tabWrapper': {
			fontWeight: 'bold'
		}
	},
	tabTextColorInherit: {},
	tabTextColorPrimary: {},
	tabTextColorSecondary: {},
	tabWrapped: {},
	tabWrapper: {}
}), { name: 'SwTabBar' });

export default ThemeLoader(StyleLoader(TabPanelBlock, Styles));

