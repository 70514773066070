import React, { Component, useState, useEffect } from 'react';
import getChildren from '../../utils/children';
import DialogContext from './context';
import DialogProps from './props';

const DialogContainerBlock = (props: DialogProps, ref: React.Ref<any>) => {
	const { content } = props;
	return <DialogContext {...props}>{getChildren(content)}</DialogContext>;
};

export default DialogContainerBlock;
