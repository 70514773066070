import field, { Style } from './field';
import type SpacerQuestionRecord from './question';

let Question: typeof import('./question').default;
if (process.env.BROWSER != 'true') {
	Question = require('./question').default;
}

let description: typeof import('./description').default;
let title: (record: SpacerQuestionRecord) => Promise<string>;
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
	title = async (record: SpacerQuestionRecord): Promise<string> => `Spacer: ${record.size || '1rem'}`;
}


export default {
	field,
	style: Style,
	description,
	title,
	isAnswerable: false,
	question: Question,
	children: true,
	isQuestion: true
}

