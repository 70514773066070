import { makeStyles } from '@material-ui/styles';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/SaveAlt';
import React from 'react';
import ArticleDownloadProps from './props';

const DownloadBlock = ({ iconPosition, icon, variant, color, size, classes, file, title }: ArticleDownloadProps, ref: React.Ref<any>) => {
	const optionalProps: { startIcon?: JSX.Element; endIcon?: JSX.Element; } = {};

	const pos: keyof typeof optionalProps = iconPosition === 'after' ? 'endIcon' : 'startIcon';
	const iconEl = (() => <Icon>{icon}</Icon>)();
	optionalProps[pos] = iconEl;

	return (
		<Button
			variant={variant}
			color={color}
			size={size}
			className={classes.downloadButton}
			href={file}
			target="_blank"
			{...optionalProps}
		>
			{title}
		</Button>
	);
};

const Styles = makeStyles(theme => ({
	root: {},
	iconArea: {},
	downloadButton: {},
	downloadIcon: {},
	downloadType: {},
	infoArea: {},
	title: {},
	summary: {},
	size: {},
}));

export default ThemeLoader(StyleLoader(DownloadBlock, Styles));
