import React, { useCallback } from 'react';
import { useBreakpoint } from '@sightworks/block';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import clsx from 'clsx';
import { FieldProps } from '../../registry';

const LocationField = ({ dispatch, id, error, title, classes, value }: FieldProps) => {
	const callback = useCallback(event => {
		dispatch({ action: 'setField', data: { [id]: event.target.value } });
	}, [ dispatch, id ]);
	const breakpoint = useBreakpoint();
	const x: Partial<TextFieldProps> = {};
	if (error) {
		x.error = true;
		x.helperText = "Please fill out this field.";
	}

	return (
		<TextField variant='filled' label={title} className={clsx(classes.field, classes.location)} value={value} onChange={callback} inputProps={{ 'aria-label': title }} {...x}/>
	);
}

export default LocationField;
export const Style = {
	location: {
		flex: 1
	}
};

