import React, { Component, useState, useEffect } from 'react';
import { useTheme } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';

const PortalProvider = React.createContext<HTMLDivElement>(null);
type PortalProps = React.PropsWithChildren<{}>;

const PortalWrapper = (props: PortalProps) => {
	const [portal, setPortal] = useState<HTMLDivElement>(null);
	const theme = useTheme<ReturnType<typeof createMuiTheme>>();

	return (
		<>
			<PortalProvider.Provider value={portal}>{props.children}</PortalProvider.Provider>
			<div
				ref={setPortal}
				style={{ position: 'absolute', top: '100%', marginTop: theme.spacing(-1), left: 0, right: 0, zIndex: 1140 }}
			/>
		</>
	);
};

export { PortalProvider as Provider, PortalWrapper as Wrapper };
