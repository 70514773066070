import React from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import ImageProps from './props';

const ImageBlock = ({ classes, img, align, border, withoutUpscale }: ImageProps, ref: React.Ref<any>) => {
	return React.createElement('img', {
		ref,
		'data-align': align,
		className: clsx(classes.root, classes[align], border ? classes[border.size] : null, {
			[classes.withoutUpscale]: withoutUpscale
		}),
		...img
	})
};

const Styles = makeStyles(
	theme => ({
		root: { width: 'max-content', display: 'block', maxWidth: '100%', height: 'auto' },
		left: { marginRight: 'auto', marginLeft: '0' },
		center: { marginLeft: 'auto', marginRight: 'auto' },
		right: { marginLeft: 'auto', marginRight: '0' },
		justify: { marginLeft: 'auto', marginRight: 'auto', width: '100%' },
		small: {},
		medium: {},
		large: {},
		withoutUpscale: {}
	}),
	{ name: 'SwRawImage' }
);

export default ThemeLoader(StyleLoader(ImageBlock, Styles));


