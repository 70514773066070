import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { Context as PaginatorContext, PaginatorContextType } from '../paginator-container/context';
import Pagination from '@material-ui/lab/Pagination';
import { BlockPropsBase } from '../..';

const Q = (props: { value: PaginatorContextType }): JSX.Element => {
    const cx = props.value;
    return (
        <Pagination count={cx.pageCount} page={cx.page + 1} onChange={(evt, page) => cx.setPage(page - 1)}/>
    )
}

const PaginatorBlock = (props: BlockPropsBase, ref?: React.Ref<any>): JSX.Element => {
    return <Q value={useContext(PaginatorContext)}/>
}

export default React.forwardRef(PaginatorBlock);