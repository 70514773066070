import React, { useContext, createContext, useMemo } from 'react';

type QueryParameters = {
	[key: string]: string | string[];
}

class Query {
	parameters: QueryParameters = {};
	_q: string = null;
	constructor(query: Query = null) {
		if (query)
			Object.assign(this.parameters, query.parameters);
	}

	get() {
		if (this._q !== null) return this._q;
		this._q = this.extend("?");
		return this._q;
	}

	parse(queryString: string) {
		let pp: QueryParameters = {};
		let query = queryString.split('&').filter(v => !!v);
		query.forEach(item => {
			let v = item.split('=');
			let key = v.shift();
			let val = v.join('=');
			key = decodeURIComponent(key);
			if (key.substr(-2) == '[]') {
				key = key.substring(0, key.length - 2);
				if (!pp[key]) pp[key] = [];
				(pp[key] as string[]).push(decodeURIComponent(val));
			} else {
				pp[key] = decodeURIComponent(val);
			}
		});
		return pp;
	}
	
	extend(url = "?", replace: string[] = [], remove: string[] = []) {
		let r: string[] = [];
		let [ path, ...queryItems ] = url.split('?');
		let query = queryItems.join('?');
		let pp = this.parse(query);
		let pp0 = {...pp};
		Object.assign(pp, this.parameters);
		replace.forEach(v => {
			if (v in pp0) pp[v] = pp0[v];
		});
		remove.forEach(v => { delete pp[v]; });
		for (let key in pp) {
			let item = pp[key];
			if (Array.isArray(item)) {
				r = r.concat(item.map(value => `${encodeURIComponent(`${key}[]`)}=${encodeURIComponent(value)}`));
			} else {
				r.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
			}
		}
		let res = r.join('&');
		if (res) return path + '?' + res;
		return path;
	}

	add(params: string | QueryParameters) {
		this._q = null;
		let pv: QueryParameters;
		if (typeof params == 'string')
			pv = this.parse(params);
		else
			pv = params;
		Object.assign(this.parameters, params);
	}
}

const QueryContext = createContext<Query>(new Query());

type QueryProps = React.PropsWithChildren<{
	query: string | QueryParameters
}>
const QueryProvider = ({ query, children }: QueryProps) => {
	let currentContext = useContext(QueryContext);
	let querySource = useMemo(() => {
		let querySource = new Query(currentContext);
		querySource.add(query);
		return querySource;
	}, [ currentContext, query ]);
	return <QueryContext.Provider value={querySource}>{children}</QueryContext.Provider>
}

export default QueryProvider;
export { QueryContext as Context };
export { QueryContext };

