import { fade } from '@material-ui/core/styles/colorManipulator';
import { ExtendedTheme } from '@sightworks/theme';

export default (theme: ExtendedTheme) => ({
	root: {
		'& table': {
			width: '100%',
			marginBottom: theme.spacing(2),
			overflowY: 'hidden',
			overflowX: 'scroll',
			// border: '1px solid',
			// borderColor: theme.palette.divider,
			borderSpacing: 0,
			'& thead': {
				background: theme.palette.secondary.main,
				color: theme.palette.secondary.contrastText,
				fontWeight: 'bold',
				textTransform: 'uppercase',
				'& th': {
					border: 0,
					verticalAlign: 'bottom',
					textAlign: 'left',
					padding: theme.spacing(1)
				}
			},
			'& tbody': {
				'& tr': {
					'& td': {
						border: 'none',
						padding: theme.spacing(1),
						verticalAlign: 'top'
					},
					'& td:first-child': {
						width: '45%'
					},
					'& td:last-child': {
						width: '10%',
						textAlign: 'right'
					},
					'&:nth-child(odd)': {
						background: theme.palette.grey[theme.palette.type == 'light' ? 200 : 900]
					}
				}
			}
		}
	}
})
