import Block from './block';
import ArticleTabProps from './props';
import { BlockPropsBase, flattenChildren } from '../..';
import { BlockType } from '../../block-type';

let getProps: typeof import('./getProps').default;
let description: typeof import('./description').default;

if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
}
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
}

const ArticleTab: BlockType = {
	component: Block,
	getChildren(props: ArticleTabProps): BlockPropsBase[] { return flattenChildren(props.content); },
	description,
	section: 'Article',
	info: {
		requiresParent: [ 'article/tabs' ],
		requiresAncestor: [ 'article/root' ],
		permittedChildren: 'button|call-out|credits|download|header|image|list|quote|text|video'.split('|').map(v => `article/${v}`),
		forbiddenAncestor: [],
		forbiddenDescendant: [ 'article/tabs' ]
	},
	getProps,
	cacheRequiresRecord: () => false
};

export default ArticleTab;
