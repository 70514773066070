import React, { useMemo, useCallback, createContext, useReducer } from 'react';
import ActionContextProvider, { Context as ActionContext } from '../../utils/actionContext';
import { Context as LinkContext } from '../../utils/linkContext';

export type DialogContextType = {
	open: boolean;
	dispatch: React.Dispatch<DialogContextAction>,
	toggle: () => void;
	state: DialogState
};

export type DialogState = {
	open: boolean;
}

const DialogContext = createContext<DialogContextType>(null);
export { DialogContext as Context };

export type DialogContextAction = DialogOpen | DialogClose | DialogToggle | DialogReset;
type DialogOpen = { type: 'open' };
type DialogClose = { type: 'close' };
type DialogToggle = { type: 'toggle' };
type DialogReset = { type: 'reset' };

function dialogReducer(state: DialogState, action: DialogContextAction): DialogState {
	// console.log({ type, action });
	
	switch (action.type) {
		case 'open':
			return { ...state, open: true };
		case 'close':
			return { ...state, open: false };
		case 'toggle':
			return { ...state, open: !state.open };
		case 'reset': 
			return { open: false };
		default:
			console.log(`Invalid action ${(action as any).type}:`, action);
			return state;
	}
}

const DialogContainer = (props: React.PropsWithChildren<{}>) => {
	const [ state, dispatch ] = useReducer(dialogReducer, {}, () => dialogReducer(void 0, { type: 'reset' }));
	const toggle = useCallback(() => { dispatch({ type: 'toggle' }) }, []);
	const show = useCallback(() => { dispatch({ type: 'open' }) }, []);
	const hide = useCallback(() => { dispatch({ type: 'close' }) }, []);
	const dialogContext = useMemo(
		() => ({
			open: state.open,
			dispatch,
			toggle,
			state
		}),
		[state]
	);

	return (
		<DialogContext.Provider value={dialogContext}>
			<ActionContextProvider>
				<LinkContext.Provider value={{ open: show, close: hide }}>
					{props.children}
				</LinkContext.Provider>
			</ActionContextProvider>
		</DialogContext.Provider>
	);
};

export default DialogContainer;
