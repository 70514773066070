import React, { useMemo, useCallback, createContext, useReducer } from 'react';

export type LightBoxIndexCallback = (index: number) => any | void;

export type LightBox = {
	open: boolean;
	dispatch: React.Dispatch<GalleryContextAction>,
	toggle: (index: number) => void;
	register: (item: GalleryItem, indexCallback: LightBoxIndexCallback) => void,
	state: LightBoxState
};

export type LightBoxState = {
	open: boolean;
	carousel: null;
	selectedIndex: number;
	elements: GalleryItem[];
	imageLoading: boolean;
}

export type GalleryItem = {
	id?: string;
	src: string;
	title: string;
	summary: string;
	credits?: string;
	embeddedObject: GalleryEmbed
};

export type GalleryEmbed = {
	icon?: string;
	embedCode: string;
	iconSize?: string
};

function compareObjectProperties<A>(a: A, b: A): boolean {
	if (a === b) return true;
	if (typeof a != typeof b) return false;
	if (typeof a != 'object') return false;

	let k: Set<keyof A> = new Set([...Object.keys(a) as (keyof A)[], ...Object.keys(b) as (keyof A)[] ]);
	for (let key of k) {
		if (a[key] !== b[key]) { return compareObjectProperties(a[key], b[key]); }
	}
	return true;
}

const GalleryContext = createContext<LightBox>(null);
export { GalleryContext as Context };

export type GalleryContextAction = GalleryOpen | GalleryClose | GalleryToggle | GallerySetCarousel | GallerySetSelectedIndex | GallerySetLoading | GalleryClearLoading | GalleryRegister | GalleryReset;
type GalleryOpen = { type: 'open' };
type GalleryClose = { type: 'close' };
type GalleryToggle = { type: 'toggle', selectedIndex?: number };
type GallerySetCarousel = { type: 'set-carousel', carousel: null };
type GallerySetSelectedIndex = { type: 'set-selected-index', selectedIndex: number };
type GallerySetLoading = { type: 'set-loading' };
type GalleryClearLoading = { type: 'clear-loading' };
type GalleryRegister = { type: 'register', element: GalleryItem, callback?: LightBoxIndexCallback };
type GalleryReset = { type: 'reset' };

function galleryReducer(state: LightBoxState, action: GalleryContextAction): LightBoxState {
	// console.log({ type, action });
	
	switch (action.type) {
		case 'open':
			return { ...state, open: true };
		case 'close':
			return { ...state, open: false };
		case 'toggle':
			return { ...state, open: !state.open, selectedIndex: 'selectedIndex' in action ? action.selectedIndex : state.selectedIndex };
		case 'set-carousel':
			return { ...state, carousel: action.carousel };
		case 'set-selected-index':
			return { ...state, selectedIndex: Math.max(0, Math.min(state.elements.length - 1, action.selectedIndex)) };
		case 'set-loading':
			return { ...state, imageLoading: true };
		case 'clear-loading':
			return { ...state, imageLoading: false };
		case 'register':
			let p = state.elements.findIndex(item => compareObjectProperties(item, action.element));
			if (p == -1) {
				p = state.elements.length;
				state = { ...state, elements: [ ...state.elements, action.element ] };
			}
			if (action.callback) {
				// Stash an immediate callback on the Promise queue. Evil? Yep.
				new Promise(resolve => resolve(true)).then(() => action.callback(p));
			}
			return state;
		case 'reset':
			return {
				open: false,
				carousel: null,
				selectedIndex: 0,
				elements: [],
				imageLoading: true
			};

		default:
			console.log(`Invalid action ${(action as any).type}:`, action);
			return state;
	}
}

const GalleryContainer = (props: React.PropsWithChildren<{}>) => {
	const [ state, dispatch ] = useReducer(galleryReducer, {}, () => galleryReducer(void 0, { type: 'reset' }));
	const signalLoad = useCallback(() => { dispatch({ type: 'clear-loading' }); }, []);
	const toggle = useCallback(
		(index = 0) => { dispatch({ type: 'toggle', selectedIndex: index }); },
		[]
	);
	const register = useCallback(
		(item, indexCallback) => { dispatch({ type: 'register', element: item, callback: indexCallback }) },
		[]
	);
	const lightBox = useMemo(
		() => ({
			open: state.open,
			dispatch,
			toggle,
			register,
			state
		}),
		[state]
	);

	return (
		<GalleryContext.Provider value={lightBox}>
			{props.children}
		</GalleryContext.Provider>
	);
};

export default GalleryContainer;
