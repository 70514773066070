import React from 'react';
import MuiTypography, { 
    TypographyClassKey as MuiTypographyClassKey, 
    TypographyTypeMap as MuiTypographyTypeMap, 
    TypographyProps as MuiTypographyProps
} from '@material-ui/core/Typography';
import type { OverridableComponent, OverrideProps } from '@material-ui/core/OverridableComponent';
import type { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface TypographyTypeMap<P = {}, D extends React.ElementType = 'span'> {
    props: P & Pick<MuiTypographyTypeMap<{}, 'span'>['props'], 'align' | 'children' | 'display' | 'gutterBottom' | 'noWrap' | 'paragraph' | 'variant' | 'variantMapping'> & {
        color?: MuiTypographyTypeMap<P, D>['props']['color'] | 'primaryContrast' | 'secondaryContrast',
    },
    defaultComponent: D,
    classKey: MuiTypographyClassKey
};

export type TypographyProps<D extends React.ElementType = TypographyTypeMap['defaultComponent'], P = {}> = OverrideProps<TypographyTypeMap<P, D>, D>;
export { MuiTypographyClassKey as TypographyClassKey };

const useStyles = makeStyles(
    function styles(theme: Theme) {
        return {
            colorPrimaryContrast: {
                color: theme.palette.primary.contrastText
            },
            colorSecondaryContrast: {
                color: theme.palette.secondary.contrastText
            }
        }
    }, { name: 'SwTypography' }
);

const Typography: OverridableComponent<TypographyTypeMap> = React.forwardRef(
    function Typography(props: TypographyProps, ref: React.Ref<any>): JSX.Element {
        const extraClasses = useStyles();
        let { color, className, ...rest } = props;
        if (color == 'primaryContrast')
            return <MuiTypography {...rest} className={clsx(className, extraClasses.colorPrimaryContrast)}/>
        else if (color == 'secondaryContrast')
            return <MuiTypography {...rest} className={clsx(className, extraClasses.colorSecondaryContrast)}/>
        else 
            return <MuiTypography {...rest} className={className} color={color}/>
    }
);

export { Typography };
export default Typography;