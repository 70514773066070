import { ExtendedTheme } from "@sightworks/theme";

export default (theme: ExtendedTheme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(8),
		'& img': {
			width: 150,
			borderRadius: '50%',
		},
		'& img + [class*=MuiTypography-root-]': {
			marginTop: theme.spacing(2),
			paddingTop: theme.spacing(2),
		},
		'& [class*=MuiTypography-h5-]': {
			paddingTop: theme.spacing(2),
			marginBottom: theme.spacing(1),
			'&:last-child': {
				marginBottom: 0,
			},
		},
		'& [class*=MuiTypography-h3-]': {
			paddingTop: theme.spacing(2),
			marginBottom: theme.spacing(1),
			'&:last-child': {
				marginBottom: 0,
			},
			'&:before': {
				content: 'open-quote'
			},
			'&:after': {
				content: 'close-quote'
			}
		},
		[theme.breakpoints.only('xs')]: {
			minHeight: 800,
			padding: theme.spacing(2),
		},
		[theme.breakpoints.only('sm')]: {
			minHeight: 800,
			padding: theme.spacing(3),
		},
		[theme.breakpoints.only('md')]: { minHeight: 800 },
		[theme.breakpoints.only('lg')]: { minHeight: 800 },
		[theme.breakpoints.only('xl')]: { minHeight: 800 },
	},
});
