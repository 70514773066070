import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import React, { useMemo, useEffect, createContext, useContext } from 'react';
import { Breakpoints } from '@material-ui/core/styles/createBreakpoints';

export type BreakpointValue = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type BreakpointInfo = Partial<Record<BreakpointValue, string>>;

const BPContext = createContext<BreakpointValue>('md');


function useBaseBreakpoint(defaultValue: BreakpointValue = 'md'): BreakpointValue {
	const theme = useTheme();
	const breakpoints = useMemo<BreakpointInfo>(() => {
		return ['xs', 'sm', 'md', 'lg', 'xl'].reduce(
			(acc, val: BreakpointValue) => ({ ...acc, [val]: theme.breakpoints.only(val) }),
			{}
		);
	}, [theme]);
	const allBps: Array<[BreakpointValue, boolean]> = (Object.entries(breakpoints) as [BreakpointValue, string][]).map(([bp, q]) => [
		bp,
		useMediaQuery(q),
	]);
	const matchedBreakpoint = allBps.find(([bp, matched]) => matched);
	// console.log({ allBps, matchedBreakpoint });
	if (matchedBreakpoint) return matchedBreakpoint[0];
	return defaultValue;
}

type BreakpointProps = React.PropsWithChildren<{
	initialValue: BreakpointValue
}>

const Breakpoint = ({ initialValue, children }: BreakpointProps) => {
	const currentBp = useBaseBreakpoint(initialValue);
	useEffect(() => {
		document.cookie = `breakpoint=${encodeURIComponent(currentBp)}; path=/`;
	}, [currentBp]);
	return <BPContext.Provider value={currentBp}>{children}</BPContext.Provider>;
}

export { Breakpoint };

export default function useBreakpoint() {
	const bp = useContext(BPContext);
	return bp;
}
