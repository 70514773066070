import { ExtendedTheme } from "@sightworks/theme";

export default (theme: ExtendedTheme) => ({
	tabButtonRoot: {
		position: 'relative',
		'&:after': {
			position: 'absolute',
			bottom: 0,
			background: theme.palette.secondary.main,
			height: 0,
			left: 0,
			right: 0,
			content: '""',
			transition: theme.transitions.create('height')
		},
		'&$tabButtonOpen:after': {
			height: 4
		}
	},
	tabButtonText: {
		transition: theme.transitions.create('color')
	},
	tabButtonOpen: {
		'& $tabButtonText': {
			color: '#000'
		}
	},

	expansionPanelSummaryText: {
		...theme.typography.h4,
		color: theme.palette.secondary.main
	},
	expansionPanelRootExpanded: {},

	expansionPanelRoot: {
		'&$expansionPanelRootExpanded': {
			marginTop: 0,
			marginBottom: 0,
			'&:before': {
				opacity: 1
			},
			'& + $expansionPanelRoot:before': {
				display: 'block'
			},
			'& $expansionPanelBox': {
				backgroundColor: 'rgb(246, 246, 246)'
			}
		},
		'& + *:not($expansionPanelRoot)': {
			marginTop: theme.spacing(2)
		}
	},

	expansionPanelBox: {
		backgroundColor: '#fff',
		transition: theme.transitions.create('background-color')
	},
	expansionPanelSummaryExpanded: {},
	expansionPanelSummary: {
		transition: theme.transitions.create('background-color'),
		'&$expansionPanelSummaryExpanded': {
			minHeight: 48,
			backgroundColor: 'rgb(246, 246, 246)'
		}
	},
	expansionPanelSummaryContent: {
		margin: `${theme.spacing(3)}px 0`,
		'&$expansionPanelSummaryExpanded': {
			margin: `${theme.spacing(3)}px 0`,
		}
	}
});
