import React, { Component, useState, useCallback } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Icon from '@material-ui/core/Icon';
import Typography from '../../components/Typography';
import getChildren from '../../utils/children';
import CollapseProps from './props';
import { IconButtonProps } from '@material-ui/core';

const getClasses = (classes: Record<string, string>, prefix: string): Record<string, string> => {
	const r: Record<string, string> = {};
	for (const key in classes) {
		if (key.startsWith(prefix)) {
			let t = key.substring(prefix.length);
			t = t.charAt(0).toLowerCase() + t.substring(1);
			r[t] = classes[key];
		}
	}
	return r;
};

const ExpansionPanelBlock = (
	{ classes, defaultOpen, header, content, customIcon, iconOnLeft, elevation, transparent, indentContent }: CollapseProps,
	ref: React.Ref<any>
) => {
	const [value, setValue] = useState(defaultOpen);
	const onChange = useCallback((event: any, expanded: boolean) => setValue(expanded), []);
	const buttonProps: IconButtonProps = {};
	if (iconOnLeft) buttonProps.edge = 'start';
	let children = getChildren(content);
	if (!children.length) {
		return null;
	}

	return (
		<div
			className={clsx(classes.root, {
				[classes.hasCustomIcon]: !!customIcon,
				[classes.iconOnLeft]: !!iconOnLeft,
				[classes.transparent]: !!transparent,
				[classes.indentContent]: !!indentContent,
			})}
			ref={ref}
		>
			<ExpansionPanel
				classes={getClasses(classes, 'panel')}
				defaultExpanded={defaultOpen}
				onChange={onChange}
				elevation={elevation}
			>
				<ExpansionPanelSummary
					classes={getClasses(classes, 'summary')}
					expandIcon={<Icon>{customIcon ? customIcon[value ? 'open' : 'closed'] : 'expand_more'}</Icon>}
					IconButtonProps={buttonProps}
				>
					<Typography className={classes.headerText} {...header} />
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.detailsRoot}>
					<div className={classes.content}>{children}</div>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</div>
	);
};

const Styles = makeStyles(
	theme => ({
		root: {},
		panelRoot: {
			'$transparent &': {
				background: 'transparent',
			},
		},
		panelRounded: {},
		panelExpanded: {},
		panelDisabled: {},
		summaryRoot: {
			padding: 0,
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		summaryExpanded: {},
		summaryFocused: {},
		summaryDisabled: {},
		summaryContent: {},
		summaryExpandIcon: {
			'$hasCustomIcon &': {
				transform: 'none',
				transition: 'none',
				'&$summaryExpanded': {
					transform: 'none',
				},
			},
			'$iconOnLeft &': {
				order: -1,
				alignSelf: 'flex-start',
				marginTop: theme.spacing(0.5),
				transition: theme.transitions.create('margin-top', { duration: theme.transitions.duration.shortest }),
				'&$summaryExpanded': {
					marginTop: theme.spacing(1.5),
				},
			},
		},
		headerText: {},
		detailsRoot: {
			'$indentContent:not($iconOnLeft) &': {
				paddingRight: theme.spacing(4.5),
			},
			'$indentContent$iconOnLeft &': {
				paddingLeft: theme.spacing(4.5),
			},
		},
		hasCustomIcon: {},
		iconOnLeft: {},
		transparent: {},
		content: {
			flex: '1 1 auto'
		},
		indentContent: {},
	}),
	{ name: 'SWCollapse' }
);

export default ThemeLoader(StyleLoader(ExpansionPanelBlock, Styles));
