import field, { Style } from './field';
import type LocationQuestionRecord from './question';

let Question: typeof import('./question').default;
if (process.env.BROWSER != 'true') {
	Question = require('./question').default;
}
let description: typeof import('./description').default;
let title: (record: LocationQuestionRecord) => Promise<string>;
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
	title = async (record: LocationQuestionRecord): Promise<string> => `Location: ${record.title}`;
}


export default {
	field,
	style: Style,
	description,
	title,
	isAnswerable: true,

	question: Question
}
