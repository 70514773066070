// Load it from the current package.
import { makeStyles } from '@material-ui/core/styles';
import React, { Component, Fragment, useState, useEffect, useRef } from 'react';
import { Registry } from '@sightworks/block';

function merge(startClasses: Record<string, string> = {}, ...rest: Record<string, string>[]) {
	const R: Record<string, Set<string>> = {};
	for (const [name, value] of Object.entries(startClasses)) {
		R[name] = new Set(value.split(/\s+/).filter(v => !!v));
	}
	for (let addClasses of rest) {
		for (const [name, value] of Object.entries(addClasses)) {
			R[name] = (R[name] || new Set()).add(value);
		}
	}
	const S: Record<string, string> = {};
	for (const [name, value] of Object.entries(R)) S[name] = [...value].join(' ');
	return S;
}

const useBackgroundBase = makeStyles(
	theme => ({
		root: {
			'--background-color': 'none',
			'--background-image': 'var(--landscape-background-image)',
			'--background-overlay': 'none',
			'--landscape-background-image': 'none',
			'--portrait-background-image': 'var(--landscape-background-image, none)',
			backgroundColor: 'var(--background-color, none)',
			backgroundImage: 'var(--background-overlay, none), var(--background-image, none)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
			backgroundRepeat: 'no-repeat',
			'&.WithBackground-video': {
				position: 'relative',
				'& > *': {
					position: 'relative',
					zIndex: 1
				},
				'& > video': {
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					zIndex: 0,
					objectFit: 'cover',
					width: '100%',
					height: '100%'
				}
			},
			'@media (orientation: portrait)': {
				'--background-image': 'var(--portrait-background-image, var(--landscape-background-image))'
			}
		}
	}),
	{ name: 'WithBackground' }
);

type UseBackgroundProps = {
	background: {
		color?: string;
		image?: string;
		portraitImage?: string;
		overlay?: string;
		video?: string;
	};
	classes?: Record<string, string>;
	target: React.FC<any> | React.ComponentClass<any, any> | React.RefForwardingComponent<any, any>;
	ref: React.Ref<any>;
}

const bgs = new Map<string, () => { root: string }>();
const useBackground = (props: UseBackgroundProps) => {
	let s = [ props.background.color, props.background.image, props.background.portraitImage, props.background.overlay ].join(':');
	if (!bgs.has(s)) {
		bgs.set(s, makeStyles(theme => ({ root: getBackgroundStyles(props) })));
	}
	return bgs.get(s)();
}

const getBackgroundStyles = (props: UseBackgroundProps): Record<string, string> => {
	let r: Record<string, string> = {};
	if (props.background.color) {
		r['--background-color'] = props.background.color;
	}
	if (props.background.image) {
		r['--landscape-background-image'] = `url(${props.background.image})`;
		if (props.background.portraitImage) {
			r['--portrait-background-image'] = `url(${props.background.portraitImage})`;
		}
	}
	if (props.background.overlay) {
		r['--background-overlay'] = props.background.overlay;
	}
	return r;
}
	
const useBackgroundDynamic = makeStyles(
	theme => ({
		root: (props: UseBackgroundProps) => {
			let r: Record<string, string> = {};
			if (props.background.color) {
				r['--background-color'] = props.background.color;
			}
			if (props.background.image) {
				r['--landscape-background-image'] = `url(${props.background.image})`;
				if (props.background.portraitImage) {
					r['--portrait-background-image'] = `url(${props.background.portraitImage})`;
				}
			}
			if (props.background.overlay) {
				r['--background-overlay'] = props.background.overlay;
			}
			return r;
		},
	}),
	{ name: 'BackgroundProps' }
);

let WithBackground = (props: React.PropsWithChildren<UseBackgroundProps>, ref: React.Ref<any>): JSX.Element => {
	const baseStyles = useBackgroundBase(props);
	const styles = useBackground(props);
	const { background, target, ...rest } = props;
	const { classes } = props;
	const video = useRef(null);

	let r: string[] = [];
	if (props.background.image) r.push('WithBackground-image');
	if (props.background.overlay) r.push('WithBackground-overlay');
	if (props.background.color) r.push('WithBackground-color');
	if (props.background.video) r.push('WithBackground-video');
	let extra = { root: r.join(' ') };

	const mergedClasses = merge(classes || {}, baseStyles || {}, styles || {}, extra || {});
	rest.classes = mergedClasses;
	rest.ref = ref;

	useEffect(() => {
		if (video.current) {
			const observer = new IntersectionObserver(entries => {
				entries.forEach(async entry => {
					let v = entry.isIntersecting ? 'play' : 'pause';
					try { await video.current[v](); } catch (e) { ; }
				});
			});
			observer.observe(video.current);
			// eslint-disable-next-line consistent-return
			return () => observer.unobserve(video.current);
		}

		return () => {};
	}, [ video.current ]);
	if (props.background.video) {
		return (
			<div className={mergedClasses.root}>
				<video src={props.background.video} autoPlay muted playsInline loop ref={video}/>
				{React.createElement(target, { ...rest, classes, ref })}
			</div>
		);
	}

	return React.createElement(target, rest);
};

type CustomBackground = {
	baseStyles: ReturnType<typeof useBackgroundBase>;
	styles: ReturnType<typeof useBackground>;
	target: UseBackgroundProps['target'];
	background: UseBackgroundProps['background'];
	targetClasses: Record<string, string>;
	video: JSX.Element;
};

export const CustomBackgroundContext = React.createContext<CustomBackground>(null);

let WithCustomBackground = (props: React.PropsWithChildren<UseBackgroundProps>, ref: React.Ref<any>): JSX.Element => {
	const baseStyles = useBackgroundBase(props);
	const styles = useBackground(props);
	const { background, target, ...rest } = props;
	const { classes } = props;
	const video = useRef(null);

	let r: string[] = [];
	if (props.background.image) r.push('WithBackground-image');
	if (props.background.overlay) r.push('WithBackground-overlay');
	if (props.background.color) r.push('WithBackground-color');
	if (props.background.video) r.push('WithBackground-video');
	let extra = { root: r.join(' ') };

	const mergedClasses = merge(baseStyles || {}, styles || {}, extra || {});

	const [cb, setCb] = useState<CustomBackground>({
		baseStyles,
		styles,
		target,
		background,
		targetClasses: mergedClasses,
		video: props.background.video ? <video src={props.background.video} autoPlay muted playsInline loop ref={video}/> : null
	});

	useEffect(() => {
		setCb(v => ({
			...v,
			video: props.background.video ? <video src={props.background.video} autoPlay muted playsInline loop ref={video} /> : null
		}));
	}, [props.background.video]);
	
	useEffect(() => {
		if (video.current) {
			const observer = new IntersectionObserver(entries => {
				entries.forEach(async entry => {
					let v = entry.isIntersecting ? 'play' : 'pause';
					try { await video.current[v](); } catch (e) { ; }
				});
			});
			observer.observe(video.current);
			// eslint-disable-next-line consistent-return
			return () => observer.unobserve(video.current);
		}

		return () => {};
	}, [ video.current ]);

	return <CustomBackgroundContext.Provider value={cb}>{React.createElement(target, rest)}</CustomBackgroundContext.Provider>;
};

WithBackground = React.forwardRef(WithBackground);

function isRefForwarder<P>(v: React.FC<P> | React.ComponentClass<P, any> | React.RefForwardingComponent<P, any>): v is React.RefForwardingComponent<P, any> {
	return typeof v == 'function' && v.length == 2;
}

export default function BackgroundLoader<P extends UseBackgroundProps>(parent: React.FC<P> | React.ComponentClass<P, any> | React.RefForwardingComponent<P, any>) {
	let target = parent;
	if (isRefForwarder(parent)) target = React.forwardRef(parent);

	const Loader: React.RefForwardingComponent<P, any> = (props: P, ref: React.Ref<any>) => {
		if ('background' in props) {
			return <WithBackground {...props} target={target} ref={ref} />;
		}
		return React.createElement(target, { ...props, ref });
	};

	Loader.displayName = `BackgroundLoader(${parent.displayName || parent.name || 'Anonymous'})`;
	return React.forwardRef(Loader);
}

export function CustomBackgroundLoader<P extends UseBackgroundProps>(parent: React.FC<P> | React.ComponentClass<P, any> | React.RefForwardingComponent<P, any>) {
	let target = parent;
	if (isRefForwarder(parent)) {
		target = React.forwardRef(parent);
	}

	const Loader: React.RefForwardingComponent<P, any> = (props: P, ref: React.Ref<any>) => {
		if ('background' in props) {
			return <WithCustomBackground {...props} target={target} ref={ref} />;
		}
		return React.createElement(target, { ...props, ref });
	}

	Loader.displayName = `CustomBackgroundLoader(${parent.displayName || parent.name || 'Anonymous'})`;
	return React.forwardRef(Loader);
}
