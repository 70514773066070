import React, { useCallback, useRef, DOMElement, useEffect } from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/core/styles';
import ReactDOMServer from 'react-dom/server';
import AddEventProps from './props';
import getChildren from '../../utils/children';
import LinkProvider from '../../utils/linkContext';
import clsx from 'clsx';

type atctoggle = { type: string, id: string };
declare namespace global {
	var addeventatc: {
		refresh(): void;
		toogle(element: Node, toggle: atctoggle): void;
		documentclick(): void;
		hide(name: string): void;
	};
	var ate_touch_capable: boolean;
};

const AddEventBlock = (props: AddEventProps, ref: React.Ref<any>) => {
	const addEvent = useRef<HTMLElement>(null);
	const cb = useCallback(node => {
		addEvent.current = node;
		if (node && typeof global.addeventatc == 'object') {
			document.removeEventListener('click', global.addeventatc.documentclick, false);
			document.addEventListener('click', global.addeventatc.documentclick, false);
			if (global.ate_touch_capable) {
				document.removeEventListener('touchend', global.addeventatc.documentclick, false);
				document.addEventListener('touchend', global.addeventatc.documentclick, false);
			}
			global.addeventatc.refresh();
		}
	}, []);

	const showAddEvent = useCallback((event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
		let h: HTMLElement = event.currentTarget;
		h.classList.add('foo-addeventatc-foo');
		h.querySelectorAll('*').forEach(c => c.classList.add('foo-addeventatc-foo'));
		let oh = global.addeventatc.hide;
		let f = (cn: string): HTMLElement => h.className == cn ? h : [].find.call(h.querySelectorAll('*'), (e: HTMLElement) => e.className == cn);
		global.addeventatc.hide = className => {
			oh.call(global.addeventatc, className);
			if (f(className)) {
				h.classList.remove('foo-addeventatc-foo');
				h.querySelectorAll('*').forEach(c => c.classList.remove('foo-addeventatc-foo'));
				global.addeventatc.hide = oh;
			}
		}
		let e = addEvent.current.querySelector('.addeventatc');
		global.addeventatc.toogle(e, { type: 'click', id: e.id });
		// addEvent.current.querySelector('.addeventatc').dispatchEvent(new Event('click'));
	}, []);

    const { buttonContainer, ...classes } = props.classes;

	return (
        <LinkProvider open={showAddEvent}>
            <div className={buttonContainer}>
                {getChildren(props.content)}
        		<div dangerouslySetInnerHTML={{
        			__html: ReactDOMServer.renderToStaticMarkup(
        				<AddEventButton {...props}/>
        			)
        		}} ref={cb}/>
        	</div>
        </LinkProvider>
    );
};

const AddEventButton = (props: AddEventProps) => (
	<div className='addeventatc' title={props.buttonTitle}>
		{Object.entries(props.eventDetails).map(([ key, value ]) => (
			<span key={key} className={key}>{value}</span>
		))}
	</div>
);

export default ThemeLoader(
	StyleLoader(
		AddEventBlock,
		makeStyles(
			theme => ({
				buttonContainer: {
					position: 'relative',
					alignSelf: 'flex-start',
					flexDirection: 'row',
					verticalAlign: 'middle',
					display: 'inline-flex',

					'& .addeventatc:not([data-loaded=true])': {
						display: 'none'
					},
					'& .addeventatc': {
						background: 'transparent',
						border: 'none',
						padding: 0,
						boxShadow: 'none !important',
						outline: 0,
						position: 'absolute',
						top: '100%',
						left: '4px',
					},
					'& .addeventatc_dropdown': {
						top: '100% !important',
						marginTop: '4px'
					},
					'& .addeventatc_icon': {
						display: 'none !important'
					}
				}
			}),
			{ name: "AddEventButton" }
		)
	)
);
