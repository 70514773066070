import field, { Style } from './field';

import type { ContentType } from '@sightworks/datastore';
import type FileUploadQuestionRecord from './question';

let Question: typeof import('./question').default;
let Answer: typeof import('./answer').default;

if (process.env.BROWSER != 'true') {
	Question = require('./question').default;
	Answer = require('./answer').default;
}

let description: typeof import('./description').default;
let title: (record: FileUploadQuestionRecord) => Promise<string>;
let addAnswerField: (type: ContentType) => void;

if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
	description = require('./description').default;
	title = async (record: FileUploadQuestionRecord): Promise<string> => `File Upload: ${record.title}`;
	addAnswerField = (type: ContentType) => { type.addFile('File', 'file'); }
}

export default {
	field,
	style: Style,
	fileUpload: true,
	description,
	title,
	isAnswerable: true,
	addAnswerField,
	question: Question,
	answer: Answer
}
