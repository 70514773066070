import React, { useState, useEffect } from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/core/styles';
import EmbedProps from './props';
import 'intersection-observer';

const EmbedBlock = ({ classes, body, insertionTime }: EmbedProps, ref: React.Ref<HTMLDivElement>) => {
	const [visible, setVisible] = useState(insertionTime == 'initial');
	const [el, setEl] = useState<HTMLDivElement>(null);
	const [savedBody, setSavedBody] = useState(body);
	useEffect(() => {
		if (insertionTime == 'initial' || insertionTime == 'afterLoad')
			setVisible(true);
		else if (el && !visible) {
			let io = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					setVisible(true);
					io.unobserve(el)
					io = null;
				}
			});
			io.observe(el);
			return () => {
				io?.unobserve(el);
			}
		}
	}, [insertionTime, el, visible]);
	useEffect(() => {
		setSavedBody(body);
	}, [body]);
	useEffect(() => {
		if (el && visible && insertionTime != 'initial') {
			let x = document.createElement('div');
			x.innerHTML = savedBody;
			x.querySelectorAll('script').forEach(s => {
				let y = document.createElement('script');
				for (let i = 0; i < s.attributes.length; i++) {
					y.setAttribute(s.attributes[i].name, s.attributes[i].value);
				}
				if (!s.src) {
					y.textContent = s.textContent;
					// y.src = `data:text/javascript,${encodeURIComponent(s.textContent)}`;
				}
				s.parentNode.insertBefore(y, s);
				s.remove();
			});
			el.innerHTML = '';
			while (x.firstChild) el.appendChild(x.firstChild);
		}
		if (ref) {
			if (typeof ref == 'function') ref(el);
			else (ref as any).current = el;
		}
	}, [el, visible, insertionTime, savedBody]);
	
	return (
		<div className={classes.root} ref={setEl} {...(visible ? { dangerouslySetInnerHTML: { __html: body } } : {})} />
	);
};

export default ThemeLoader(
	StyleLoader(
		EmbedBlock,
		makeStyles(
			theme => ({
				root: {},
			}),
			{ name: 'SWEmbed' }
		)
	)
);
