import React from 'react';
import Typography from '../../components/Typography';
import { makeStyles } from '@material-ui/styles';
import { StyleLoader } from '@sightworks/theme';
import clsx from 'clsx';
import ArticleListProps from './props';

const ListBlock = ({ listItems, variant, title, classes }: ArticleListProps, ref: React.Ref<any>) => {
	const Node = variant == 'numbered' ? 'ol' : 'ul';

	return (
		<div className={classes.root} ref={ref}>
			{title && (
				<Typography variant="h4" className={classes.title}>
					{title}
				</Typography>
			)}
			<Node className={classes[variant]}>
				{listItems.map((text, index) => (
					<li key={index}>{text}</li>
				))}
			</Node>
		</div>
	);
};

const Styles = makeStyles(theme => ({
	root: {},
	bulleted: {},
	numbered: {},
	title: {},
}));

export default StyleLoader(ListBlock, Styles);
