import Block from './block';
import ArticleListProps from './props';
import { BlockPropsBase } from '../../props';
import { BlockType } from '../../block-type';
import type BlockRecord from '../../record';

let description: typeof import('./description').default;
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
}

let getProps: typeof import('./getProps').default;
if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
}

const ArticleList: BlockType = {
	component: Block,
	getChildren(props: ArticleListProps): BlockPropsBase[] {
		return [];
	},
	description,
	async subLabel(record: BlockRecord) {
		return record.variant;
	},
	section: 'Article',
	info: {
		requiresParent: [ 'article/root', 'article/tab' ],
		permittedChildren: [], 
		forbiddenAncestor: [],
		forbiddenDescendant: []
	},
	getProps,
	cacheRequiresRecord: () => false
};

export default ArticleList;

