import Block from './block';
import ArticleTabsProps from './props';
import { BlockPropsBase, BlockType, flattenChildren } from '../..';

let getProps: typeof import('./getProps').default;
let description: typeof import('./description').default;

if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
}
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
}

const ArticleTabs: BlockType = {
	component: Block,
	getChildren(props: ArticleTabsProps): BlockPropsBase[] {
		return flattenChildren(props.content);
	},
	description,
	section: 'Article',
	info: {
		requiresParent: [ 'article/root' ],
		requiresAncestor: [ 'article/root' ],
		permittedChildren: [ 'article/tab' ],
		forbiddenAncestor: [],
		forbiddenDescendant: []
	},
	getProps,
	cacheRequiresRecord: () => false
};

export default ArticleTabs;