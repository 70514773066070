import React, { useState } from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import Icon from '@material-ui/core/Icon';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import IconProps from './props';
import { Theme } from '@material-ui/core';

const IconBlock = ({ classes, icon, color }: IconProps, ref: React.Ref<any>) => {
	const iconComponents = {
		facebook: FacebookIcon,
		instagram: InstagramIcon,
		twitter: TwitterIcon,
		youtube: YouTubeIcon,
		linkedIn: LinkedInIcon,
		default: Icon,
	};

	if (!icon) return null;
	
	const Component: React.FC<{className: string, ref: React.Ref<any>}> = (() => {
		let name: keyof (typeof iconComponents);
		let v: string = icon.split('svg:')[1] || 'default';
		if (v in iconComponents) name = v as typeof name;
		else name = 'default';
		return name in iconComponents ? iconComponents[name] : iconComponents.default;
	})();

	const IconBlock = (
		<Component
			className={clsx(classes.root, {
				[classes.default]: color === 'default',
				[classes.primary]: color === 'primary',
				[classes.secondary]: color === 'secondary',
			})}
			ref={ref}
		>
			{icon.startsWith('svg:') ? null : icon}
		</Component>
	);

	return IconBlock;
};

export default ThemeLoader(StyleLoader(
	IconBlock,
	makeStyles(
		(theme: Theme) => ({
			root: {},
			default: {
				color: theme.palette.text.primary,
			},
			primary: {
				color: theme.palette.primary.main,
			},
			secondary: {
				color: theme.palette.secondary.main,
			},
		}),
		{ name: 'SWIcon' }
	)
));
