import Block from './block';
import ArticleCreditsProps from './props';
import { BlockPropsBase } from '../../props';
import { BlockType } from '../../block-type';

let description: typeof import('./description').default;
let getProps: typeof import('./getProps').default;

if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
	description = require('./description').default;
}
if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
}
// import getProps from './getProps';

const ArticleCredits: BlockType = {
	component: Block,
	getChildren(props: ArticleCreditsProps): BlockPropsBase[] { return []; },
	description,
	section: 'Article',
	info: {
		requiresParent: null,
		requiresAncestor: [ 'article/root' ],
		permittedChildren: [],
		forbiddenAncestor: [],
		forbiddenDescendant: []
	},
	getProps,
	cacheRequiresRecord: () => false
};

export default ArticleCredits;
