import React from 'react';

type JsonProps = {
    target: any;
    name?: string;
    depth?: number;
}

const Json = ({ target, name, depth }: JsonProps): JSX.Element => {
    depth = depth || 0;
    switch (typeof target) {
        case 'boolean':
        case 'string':
        case 'number':
            return (
                <span className='row'>
                    <span className='expando empty'/>
                    <span className='name' style={{ '--depth': depth } as React.CSSProperties}>{name}</span>
                    <span className='value'>{JSON.stringify(target)}</span>
                </span>
            )
        case 'object':
            if (target === null) {
                return (
                    <span className='row'>
                        <span className='expando empty'/>
                        <span className='name' style={{ '--depth': depth } as React.CSSProperties}>{name}</span>
                        <span className='value'>NULL</span>
                    </span>
                );
            }
            if (Array.isArray(target)) {
                return <JsonArray target={target} name={name} depth={depth} />
            }
            return <JsonObject target={target} name={name} depth={depth} />
        
        default:
            return null;
    }
}

const JsonArray = ({ target, name, depth }: { name: string, depth: number, target: any[] }): JSX.Element => {
    const [ open, setOpen ] = React.useState(false);
    const toggleOpen = React.useCallback(() => setOpen(open => !open), []);

    return (
        <>
            <span className='row'>
                <span className={target.length > 0 ? 'expando' : 'expando empty'}>
                    {target.length > 0 && <a onClick={toggleOpen} className='toggle'>{open ? '-' : '+'}</a>}
                </span>
                <span className='name' style={{ '--depth': depth } as React.CSSProperties}>{name}</span>
                <span className='value'>[{target.length} items]</span>
            </span>
            {open && <>
                {target.map((item, index) => <Json depth={depth + 1} name={String(index)} target={item} key={index}/>)}
            </>}
        </>
    )
}

const JsonObject = ({ target, name, depth }: { name: string, depth: number, target: Record<string, any> }): JSX.Element => {
    const [ open, setOpen ] = React.useState(false);
    const toggleOpen = React.useCallback(() => setOpen(open => !open), []);

    return (
        <>
            <span className='row'>
                <span className={Object.keys(target).length > 0 ? 'expando' : 'expando empty'}>
                    {Object.keys(target).length > 0 && <a onClick={toggleOpen} className='toggle'>{open ? '-' : '+'}</a>}
                </span>
                <span className='name' style={{ '--depth': depth } as React.CSSProperties}>{name}</span>
                <span className='value'>[{Object.keys(target).length} properties]</span>
            </span>
            {open && <>
                {Object.keys(target).map((item, index) => <Json depth={depth + 1} name={item} target={target[item]} key={index}/>)}
            </>}
        </>
    )
}

export default ({ target, name }: JsonProps): JSX.Element => {
    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: `
                <style type='text/css'>
                    .json {
                        display: grid;
                        grid-template-columns: 20px 50vw 1fr;
                        grid-gap: 8px;
                        font-family: monospace;
                    }
                    .row {
                        display: contents;
                    }
                    .name {
                        padding-left: calc(var(--depth, 0) * 1rem);
                    }
                </style>
            ` }}/>
            <div className='json'>
                <Json target={target} name={name || '[Root]'} depth={0} />
            </div>
        </div>
    )
}
