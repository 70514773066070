export interface FakeEventInit {
	bubbles?: boolean;
	cancelable?: boolean;
	scoped?: boolean;
	composed?: boolean
	synchronous?: boolean;
};

const ImmediateStopped = Symbol();
const ListenerSym = Symbol("Listeners");

function objectWithoutProperty<T extends {}, P extends keyof T>(object: T, key: P): T extends {[key: string]: infer U} ? Record<string, U> : Omit<T, P> {
	let R: any = {};
	let K: keyof T;
	for (K in object) {
		if (K == key) continue;
		else R[K as keyof typeof R] = object[K];
	}
	return R;
}

export class FakeEvent implements Event {
	readonly type: string;
	readonly bubbles: boolean;
	readonly cancelable: boolean;
	readonly scoped: boolean;
	readonly timeStamp: number;
	readonly eventPhase: number;
	readonly synchronous: boolean;
	returnValue: boolean;
	/** @deprecated */ srcElement: FakeEventTarget | null;
	readonly target: FakeEventTarget | null;

	NONE = 0;
	CAPTURING_PHASE = 1;
	AT_TARGET = 2;
	BUBBLING_PHASE = 3;
	static NONE = 0;
	static CAPTURING_PHASE = 1;
	static AT_TARGET = 2;
	static BUBBLING_PHASE = 3;

	constructor(type: Event | FakeEvent | string, eventInit?: FakeEventInit) {
		if (type instanceof Event || type instanceof FakeEvent) {
			Object.defineProperties(this, {
				type: { enumerable: true, value: type.type },
				bubbles: { enumerable: true, value: type.bubbles },
				cancelable: { enumerable: true, value: type.cancelable },
				scoped: { enumerable: true, value: 'scoped' in type ? type.scoped : false },
				timeStamp: { enumerable: true, value: type.timeStamp },
				synchronous: { enumerable: true, value: type instanceof FakeEvent ? type.synchronous : false }
			});
		} else {
			if (!eventInit) eventInit = {};
			eventInit = Object.assign({
				bubbles: false,
				cancelable: false,
				scoped: false,
				composed: false,
				synchronous: false,
			}, eventInit);
			
			Object.defineProperties(this, {
				type: { enumerable: true, configurable: false, writable: false, value: type },
				bubbles: { enumerable: true, value: eventInit.bubbles },
				cancelable: { enumerable: true, value: eventInit.cancelable },
				scoped: { enumerable: true, value: eventInit.scoped },
				timeStamp: { enumerable: true, value: Date.now() },
				synchronous: { enumerable: true, value: eventInit.synchronous }
			});
		}
	}
	
	/** @deprecated */
	initEvent(type: string, bubbles?: boolean, cancelable?: boolean): void {
		throw new TypeError("Use 'new FakeEvent()', not initEvent()");
	}

	composedPath() {
		return [this.currentTarget];
	}

	get defaultPrevented() {
		return false;
	}
	
	get isTrusted() {
		return false;
	}
	
	preventDefault() {
		if (!this.defaultPrevented && this.cancelable)
			Object.defineProperty(this, 'defaultPrevented', { enumerable: true, value: true });
	}
	
	
	stopPropagation() {}
	get [ImmediateStopped]() {
		return false;
	}
	
	stopImmediatePropagation() {
		if (!this[ImmediateStopped])
			Object.defineProperty(this, ImmediateStopped, { value: true });
	}

	cancelBubble: boolean = false;
	readonly composed = false;
	readonly currentTarget: FakeEventTarget = null;
}

export interface ListenerChangeEventInit extends FakeEventInit {
	name: string;
	handler: EventListenerOrEventListenerObject;
	options: AddEventListenerOptions;
}
export class ListenerChangeEvent extends FakeEvent {
	name: string;
	handler: EventListenerOrEventListenerObject;
	options: AddEventListenerOptions;
	constructor(type: string, init: ListenerChangeEventInit) {
		super(type, init);
		this.name = init.name;
		this.handler = init.handler;
		this.options = init.options;
	}
}

export class FakeEventTarget implements EventTarget {
	[ListenerSym]: Record<string, { handler: EventListenerOrEventListenerObject, once: boolean }[]>;

	constructor() {
		this[ListenerSym] = {};
	}
	
	addEventListener(name: string, handler: EventListenerOrEventListenerObject, useCapture: boolean | AddEventListenerOptions = false) {
		if (!this[ListenerSym][name]) {
			this[ListenerSym][name] = [];
		}
		if (this[ListenerSym]['addListener']?.length) {
			this.dispatchEvent(new ListenerChangeEvent('addListener', { name, handler, options: typeof useCapture == 'boolean' ? { capture: useCapture } : useCapture, synchronous: true }));
		}
		this[ListenerSym][name].push({
            handler: handler,
            once: typeof useCapture == 'object' && useCapture && useCapture.once || false
		});
	}
	
	on(names: string, handler: (event: FakeEvent) => any) {
		names.split(/\s+/g).forEach(name => {
			this.addEventListener(name, handler);
		});
		return this;
	}
	
	off(names: string, handler: (event: FakeEvent) => any = null) {
		names.split(/\s+/g).forEach(name => {
			if (handler)
				this.removeEventListener(name, handler);
			else if (this[ListenerSym]['removeListener']?.length) {
				let r = this[ListenerSym][name];
				for (let item of r) {
					this.removeEventListener(name, item.handler);
				}
			} else {
				this[ListenerSym] = objectWithoutProperty(this[ListenerSym], name);
			}
		});
		
		return this;
	}
	
	removeEventListener(name: string, handler: EventListenerOrEventListenerObject, useCapture = false) {
		this[ListenerSym][name] = this[ListenerSym][name] ?? [].filter(item => {
			if (this[ListenerSym]['removeListener']?.length && item.handler == handler) {
				this.dispatchEvent(new ListenerChangeEvent('removeListener', { name, handler, options: {} }));
			}
			return item.handler != handler;
		});
		if (this[ListenerSym][name].length == 0) {
			this[ListenerSym] = objectWithoutProperty(this[ListenerSym], name);
		}
	}
	
	dispatchEvent(event: Event | FakeEvent) {
		// If the event can be cancelled, we need to know it's result.
		// If it can't be cancelled, queue it on the next animation frame.
		if (event.cancelable || (event instanceof FakeEvent && event.synchronous)) {
			return this._dispatcher(event);
		} else {
			requestAnimationFrame(() => {
				this._dispatcher(event);
			});
			return false;
		}
	}

	private _dispatcher(event: Event | FakeEvent) {
		try {
			console.log(`Dispatch event to`, this, ':', event);
		} catch (e) { ; }

		var T: FakeEvent = event instanceof FakeEvent ? event : new FakeEvent(event);
		
		Object.defineProperties(T, {
			target: { enumerable: true, value: this },
			currentTarget: { enumerable: true, value: this },
			eventPhase: { enumerable: true, value: 2 }
		});
		
		if (this[ListenerSym][T.type]) {
			for (var i = 0; !T[ImmediateStopped] && i < this[ListenerSym][T.type].length; i++) {
				var L = this[ListenerSym][T.type][i];
				if (L) {
					try {
						let handler = L.handler;
						let r;
						if (typeof handler == 'function')
							r = handler.call(this, event);
						else
							r = handler.handleEvent(event);

						if (r === false) {
							T.preventDefault();
						}
					} catch (e) {
						console.error(e);
						throw e;
					}
				}
			}
		}
		
		if (T.defaultPrevented)
			return false;
		
		return true;
	}
	
	protected mapEvent(object: Record<string, any>): FakeEvent {
		return new FakeEvent(object.type);
	}
	trigger(name: string | Record<string, any>) {
		var e = this.mapEvent(typeof name == 'string' ? { type: name } : name)
		this.dispatchEvent(e);
		return this;
	}
	
	triggerHandler(name: string | Record<string, any>) {
		var e = this.mapEvent(typeof name == 'string' ? { type: name } : name);
		return this.dispatchEvent(e);
	}		

	static mixin(target: FakeEventTarget): void {
		target[ListenerSym] = {};
		target.addEventListener = FakeEventTarget.prototype.addEventListener;
		target.on = FakeEventTarget.prototype.on;
		target.off = FakeEventTarget.prototype.off;
		target.removeEventListener = FakeEventTarget.prototype.removeEventListener;
		target.dispatchEvent = FakeEventTarget.prototype.dispatchEvent;
		target._dispatcher = FakeEventTarget.prototype._dispatcher;
		target.trigger = FakeEventTarget.prototype.trigger;
		target.triggerHandler = FakeEventTarget.prototype.triggerHandler;
	}
}
