import React, { useState, useContext, useCallback } from 'react';
import getChildren, { flattenChildren } from '../../utils/children';
import { QueryContext } from '../../utils/query';
import FilterSelectItemProps, { isFilterSelectItem } from '../filter-select-item/props';
import FilterContext from '../filter/context';
import FilterSelectProps from './props';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';

import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import { Icon } from '@material-ui/core';

const ValidId = (s: string) => `id_${s.replace(/[^0-9A-Za-z]/g, '_')}`;

const FilterSelectBlock = (props: FilterSelectProps): JSX.Element => {
    const classes = props.classes;
    const filterContext = useContext(FilterContext);
    const queryContext = useContext(QueryContext);
    const [ open, setOpen ] = useState<HTMLElement>(null);
    const name = filterContext + '_' + props.name;
    const item: FilterSelectItemProps = flattenChildren(props.content).filter(isFilterSelectItem).find(v => v.value == queryContext.parameters[name]);
    const doSetOpen = useCallback((event: React.MouseEvent<HTMLElement>) => setOpen(event.currentTarget), []);
    const doSetClosed = useCallback(() => setOpen(null), []);
    return (<>
        <Button aria-controls={ValidId(props.id + ':menu')} aria-haspopup='true' onClick={doSetOpen} className={classes.button} endIcon={<Icon>expand_more</Icon>}>
            {item ? item.name : (props.unspecifiedLabel || 'Filter')}
        </Button>
        <Menu anchorEl={open} open={!!open} keepMounted id={ValidId(props.id + ':menu')} onClose={doSetClosed} className={classes.menu}>
            <ListItem selected={!item} component='a' href={queryContext.extend(`${typeof location != 'undefined' ? location.pathname : ''}`, [], [name])} className={clsx(classes.item, { [classes.selected]: !item })}>
                {props.noFilterText || 'None'}
            </ListItem>
            {flattenChildren(props.content).filter(isFilterSelectItem).map(current => (
                <ListItem selected={current === item} component='a' href={queryContext.extend(`${typeof location != 'undefined' ? location.pathname : ''}?${encodeURIComponent(name)}=${encodeURIComponent(current.value)}`, [name])} key={current.id} className={clsx(classes.item, { [classes.selected]: current === item })}>
                    {current.name}
                </ListItem>
            ))}
        </Menu>
    </>)
}

export default ThemeLoader(StyleLoader(FilterSelectBlock, makeStyles({
    button: {},
    menu: {},
    selected: {},
    item: {}
}, { name: 'SWFilterSelectBlock' })));
