import React, { useContext } from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';

import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import Typography from '../../components/Typography';
import { CSSProperties } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useLinkAttributes } from '../../utils/linkContext';
import NavButtonProps from './props';
import { Typography as TypographyInfo } from '@material-ui/core/styles/createTypography';

const NavButtonBlock = ({ icon, title, variant, color, link, classes }: NavButtonProps, ref: React.Ref<any>) => {
	const lp = useLinkAttributes(link);

	return (
		<ButtonBase classes={{ root: classes.root }} focusRipple ref={ref} {...lp}>
			{icon && <Icon className={clsx(classes.icon, classes[`icon-${variant}`])}>{icon}</Icon>}
			<Typography variant={variant} color={color} align="inherit">
				{title}
			</Typography>
		</ButtonBase>
	);
};

function isTypographyObject(v: any): v is CSSProperties {
	return v && typeof v == 'object';
}
const makeTypography = (type: TypographyInfo) => {
	const r: Record<string, CSSProperties> = {};
	for (const item in type) {
		let v: any = (type as any)[item];
		if (isTypographyObject(v)) {
			r[`icon-${item}`] = {
				fontSize: v.fontSize
			};
		}
	}
	return r;
};

const NavButtonStyles = makeStyles(
	(theme) => {
		const r: Record<string, CSSProperties> = {
			root: {
				textAlign: 'left',
			},
			icon: {},
			...makeTypography(theme.typography),
		};
		return r;
	},
	{ name: 'NavButton' }
);

const ThemedNavButtonBlock = ThemeLoader(StyleLoader(NavButtonBlock, NavButtonStyles));
export default ThemedNavButtonBlock;
