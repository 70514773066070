import React, { useCallback } from 'react';
import { useBreakpoint } from '@sightworks/block';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import clsx from 'clsx';
import EmailAddressBody from './body';

const EmailField = ({ dispatch, id, error, title, classes, value, status }: EmailAddressBody) => {
	const callback = useCallback(event => {
		dispatch({ action: 'setField', data: { [id]: event.target.value } });
	}, [ dispatch, id ]);
	const breakpoint = useBreakpoint();
	const x: Partial<TextFieldProps> = {};
	if (error) {
		x.error = true;
		x.helperText = "Please fill out this field.";
	}

	return (
		<TextField variant='filled' label={title} className={clsx(classes.field, classes.emailAddress)} value={value} onChange={callback} disabled={status != 'READY'} {...x} inputProps={{ 'aria-label': title }}/>
	);
}

export default EmailField;
export const Style = {
	emailAddress: {
		flex: 1
	}
};
