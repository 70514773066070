import { BlockPropsBase, BlockType, flattenChildren } from '../..';
import Block from './block';

let description: typeof import('./description').default;
let getProps: typeof import('./getProps').default;

if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
    description = require('./description').default;
}
if (process.env.BROWSER != 'true') {
    getProps = require('./getProps').default;
}

const FilterBlock: BlockType = {
    component: Block,
    getChildren: (props: BlockPropsBase) => flattenChildren(props.content),
    description,
    info: {
        requiresParent: null,
        requiresAncestor: null,
        permittedChildren: null,
        forbiddenAncestor: [],
        forbiddenDescendant: []
    },
    getProps,
    section: 'Specialty',
    cacheRequiresRecord: () => false
};

export default FilterBlock;
