import Block from './block';
import DrawerProps from './props';
import { BlockType, flattenChildren } from '../..';

let getProps: typeof import('./getProps').default;
let description: typeof import('./description').default;
let appendNode: typeof import('../../utils/parent').default;

if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
	appendNode = require('../../utils/parent').default;
}
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
}

const DrawerBlock: BlockType = {
	component: Block,
	getChildren(props: DrawerProps) { return flattenChildren(props.content); },
	description,
	info: {
		// null | array<string>: what container must this be a direct child of; null = anything.
		// If requiresParent is the empty array, it can never be selected as a child element.
		requiresParent: null,

		// null | array<string>: what container must be somewhere above this; null = anything
		// If requiresAncestor is the empty array, this component can never appear in the tree.
		// Anything specified in requiresParent must also be specified here.
		requiresAncestor: null,

		// null | array<string>: what can this element have for children; null = anything; empty array marks this as an empty component without children.
		permittedChildren: null,

		// array<string>: where can this element not appear
		forbiddenAncestor: ['drawer'],

		// array<string>: what elements cannot appear inside of this one
		forbiddenDescendant: ['drawer']
	},
	getProps,
	appendNode,
	cacheRequiresRecord: () => false,
	section: 'Navigation'
};
export default DrawerBlock;

