import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FieldProps } from '../../registry';
import HeaderBody from './body';

const HeaderField = ({ classes, text }: HeaderBody) => <Typography className={classes.header} variant="h5">{text}</Typography>;

export default HeaderField;
export const Style = {
	header: {}
};

