const isCached = (imgSrc: string) => {
	const img = new Image();
	img.src = imgSrc;

	return img.complete;
};

const cacheImage = (imgSrc: string) => {
	const img = new Image();
	return new Promise<string>((resolve, reject) => {
		img.onload = () => resolve(img.src);
		img.onerror = () => reject(img.src);
		img.src = imgSrc;
	});
}

export default isCached;
export { cacheImage };

