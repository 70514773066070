import React, { useState, useEffect, useRef, useCallback } from 'react';
import CalendlyWidgetProps from './props';

type CalendlyConstructorParam = {
	parentElement: HTMLElement,
	inlineStyles: boolean,
	embedType: string
}

declare namespace global {
	namespace Calendly {
		class Iframe {
			constructor(arg: CalendlyConstructorParam);
		}
	}
}

let Loaded = false;
type LoadHandler = () => void;
type P = { loaded: LoadHandler };

const CalendlyWidgetScript = ({ loaded }: P) => {
	let ref = useRef(null);
	useEffect(() => {
		if (!Loaded) {
			let n = document.createElement('script');
			n.async = true;
			n.defer = true
			n.src = 'https://assets.calendly.com/assets/external/widget.js';
			n.onload = () => {
				Loaded = true;
				loaded();
			}
			ref.current.appendChild(n);
		}
	}, []);

	return <span ref={ref}/>;
};

const CalendlyWidget = ({ url }: CalendlyWidgetProps, ref: React.Ref<any>) => {
	let [ scriptReady, setScriptReady ] = useState(false);
	let node = useRef(null);
	let callback = useCallback(() => {
		setScriptReady(true);
	}, []);
	
	useEffect(() => {
		if (scriptReady) {
			new global.Calendly.Iframe({
				parentElement: node.current,
				inlineStyles: false,
				embedType: 'Inline'
			});
		}
	}, [ scriptReady ]);

	return (
		<>
			<CalendlyWidgetScript loaded={callback}/>
			<div ref={node} className='calendly-inline-widget' data-auto-load='false' data-url={url} style={{ minWidth: '320px', height: '630px' }}/>
		</>
	);
}

export default React.forwardRef(CalendlyWidget);
