import { ExtendedTheme } from "@sightworks/theme";

export default (theme: ExtendedTheme) => ({
	root: {
		textAlign: 'center',
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'column',
	},
	actionArea: {
		display: 'flex',
		flexDirection: 'column',
		height: 'auto',
		flex: '1 1 auto',
		alignItems: 'stretch',
	},
	cardContent: {
		display: 'flex',
		textAlign: 'left',
		flexDirection: 'column',
		justifyContent: 'center',
		flex: '1 1 35%',
		minHeight: '10vh',
		padding: theme.spacing(4),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2),
		},
	},
	content: {},
	title: {
		margin: 'auto 0',
		marginTop: '0',
		paddingBottom: '0.35em',
		minHeight: `64px`,
	},
	caption: {
		...theme.typography.overline,
	},
});
