import Box from '@material-ui/core/Box';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import React from 'react';
import getChildren from '../../utils/children';
import { BlockPropsBase } from '../..';

const DetailsBlock = (props: BlockPropsBase, ref: React.Ref<any>) => (
	<div ref={ref} className={props.classes.root}>
		{getChildren(props.content)}
	</div>
);
export default ThemeLoader(StyleLoader(DetailsBlock));
