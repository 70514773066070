import React, { useContext, useCallback } from 'react';
import Modal from '@material-ui/core/Modal';
import { Context } from '../gallery-container/context';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import getChildren from '../../utils/children';
import { makeStyles } from '@material-ui/core/styles';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { BlockPropsBase } from '../..';

const GalleryView = (props: BlockPropsBase, ref: React.Ref<any>) => {
	let context = useContext(Context);
	let { classes, content } = props;
	let close = useCallback(() => context.dispatch({ type: 'close' }), [context.dispatch]);
	
	return (
		<Modal className={classes.root} open={context.open} onClose={close} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500, className: classes.backdrop }}>
			<Fade in={context.open}>
				<div className={classes.galleryViewBody}>
					{getChildren(content)}
					<IconButton className={classes.closeIconButton} onClick={close}>
						<Icon className={classes.closeIcon}>close</Icon>
					</IconButton>
				</div>
			</Fade>
		</Modal>
	);
}

export default ThemeLoader(
	StyleLoader(
		GalleryView,
		makeStyles(theme => ({
			root: {},
			closeIconButton: {
				position: 'fixed',
				top: 0,
				right: 0,
				margin: theme.spacing(0),
				userSelect: 'none',
				cursor: 'pointer',
				color: theme.palette.text.hint,
				transition: theme.transitions.create('color'),
				zIndex: 5,
				'&:hover': {
					color: theme.palette.text.primary
				},
				[theme.breakpoints.down('md')]: {
					margin: theme.spacing(1)
				}
			},
			closeIcon: {
				fontSize: '2.5rem'
			},
			galleryViewBody: {
				padding: `56px 56px 40px`,
				height: '100%',
				[theme.breakpoints.down('md')]: {
					margin: 0,
					padding: theme.spacing(2)
				},
				display: 'flex',
				flexDirection: 'column',
				'& > [class*=SwGalleryItems-scroller]': {
					marginTop: theme.spacing(5)
				}
			},
			backdrop: {
				backgroundColor: `rgba(${theme.palette.type == 'dark' ? '0, 0, 0' : '255, 255, 255'}, 1)`
			}
		}), { name: 'SwGalleryView' })
	),
	null,
	"dark"
);

