import HomeTile from './home-tile';
import BasicTile from './basic-tile';
import HomeBar from './home-bar';
import HeaderBanner from './header-banner';
import HomeHeaderBanner from './home-header-banner';
import SmallHeaderBanner from './small-header-banner';
import SmallHeaderBannerAlt from './small-header-banner-alt';
import HomeEnrollGrid from './home-enroll-grid';
import TestimonialCarousel from './testimonial-carousel';
import NavDrawer from './nav-drawer';
import MobileNavButton from './mobile-nav-button';
import SiteFooter from './site-footer';
import EventCard from './event-card';
import Calendar from './calendar';
import MediaEmbed from './media-embed';
import BottomLinks from './bottom-links';
import LinkCard from './link-card';
import LinkCard2 from './link-card-2';
import LinkCard3 from './link-card-3';
import OpaqueIconButton from './opaque-icon-button';
import GreyButton from './grey-button';
import Bold from './bold';
import TuitionTable from './tuition-tables';
import SiteNavigation from './site-navigation';
import PersonDetailHeader from './person-detail-header';

export default {
	'home-tile': {
		title: 'Home Page Tiles',
		style: HomeTile,
	},
	'basic-tile': {
		title: 'Basic Tiles',
		style: BasicTile,
	},
	'home-bar': {
		title: 'Home Page "Keep Exploring" bar',
		style: HomeBar,
	},
	'header-banner': {
		title: 'Header Banner',
		style: HeaderBanner,
	},
	'home-header-banner': {
		title: 'Home Header Banner',
		style: HomeHeaderBanner,
	},
	'small-header-banner': {
		title: 'Small Header Banner',
		style: SmallHeaderBanner,
	},
	'small-header-banner-alt': {
		title: 'Small Header Banner (alt)',
		style: SmallHeaderBannerAlt,
	},
	'home-enroll-grid': {
		title: 'Home - "Ready to Enroll" grid',
		style: HomeEnrollGrid,
	},
	'testimonial-carousel': {
		title: 'Testimonial Carousel',
		style: TestimonialCarousel,
	},
	'nav-drawer': {
		title: 'Navigation Drawer Item',
		style: NavDrawer,
	},
	'mobile-nav-button': {
		title: 'Mobile Nav Button',
		style: MobileNavButton,
	},
	'site-footer': {
		title: 'Site Footer',
		style: SiteFooter,
	},
	'event-card': {
		title: 'Event Card',
		style: EventCard,
	},
	calendar: {
		title: 'Calendar',
		style: Calendar,
		onlyOn: ['calendar'],
	},
	'media-embed': {
		title: 'Embedded media (<iframe> or <video>)',
		style: MediaEmbed,
		onlyOn: ['embed', 'raw/embed'],
	},
	'bottom-links': {
		title: 'Bottom Links',
		style: BottomLinks,
	},
	'link-card': {
		title: 'Link Card',
		style: LinkCard,
	},
	'link-card-2': {
		title: 'Link Card (2)',
		style: LinkCard2,
	},
	'link-card-3': {
		title: 'Link Card (3)',
		style: LinkCard3,
	},
	'opaque-icon-button': {
		title: 'Opaque (white background) icon button',
		style: OpaqueIconButton,
		onlyOn: ['raw/icon-button', 'button'],
	},
	'grey-button': {
		title: 'Transparent grey button',
		style: GreyButton,
		onlyOn: ['raw/button', 'button'],
	},
	bold: { title: 'Bold', style: Bold },
	'tuition-tables': {
		title: 'Tuition table',
		style: TuitionTable,
	},
	'site-navigatgion': { title: 'Site Navigation', style: SiteNavigation },
	'person-detail-header': { title: 'Person detail header', style: PersonDetailHeader },
};
