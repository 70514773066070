import yellow from '@material-ui/core/colors/yellow';
import HeaderBanner from './header-banner';
import { ExtendedTheme } from '@sightworks/theme';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export default (theme: ExtendedTheme) => {
	const lightYellow = yellow[100];
	const s: any = HeaderBanner(theme);
	(['xs', 'sm', 'md', 'lg', 'xl'] as Breakpoint[]) .forEach(v => {
		const b = theme.breakpoints.only(v);
		s.root[b].minHeight *= 0.6;
	});
	s.root['& .MuiTypography-h6, & [class*=MuiTypography-h6-], & h6'].fontStyle = 'italic';
	s.root['& .MuiTypography-h6, & [class*=MuiTypography-h6-], & h6'].color = lightYellow;
	s.root['& .MuiTypography-h5, & [class*=MuiTypography-h5-], & h5'].fontSize = theme.typography.h6.fontSize;
	s.root['& .MuiTypography-h5, & [class*=MuiTypography-h5-], & h5'].lineHeight = theme.typography.h6.lineHeight;
	s.root['& .MuiTypography-h5, & [class*=MuiTypography-h5-], & h5'].color = lightYellow;
	s.root['& .MuiTypography-h5, & [class*=MuiTypography-h5-], & h5'].fontStyle = 'italic';
	s.root[theme.breakpoints.only('xl')]['& .MuiTypography-root, & [class*=MuiTypography-root-]'].maxWidth = 1100;
	return s;
};
