import React, { useState, useEffect, useCallback } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import getChildren from '../../utils/children';
import 'intersection-observer'; // for pollyfill
import BoxProps from './props';

const BoxBlock = ({ box, elementName, noCollapseMargin, minHeight, classes, content, setBannerCallback, skew }: BoxProps, ref: React.Ref<any>) => {
	const [toggle, setToggle] = useState(false);
	const [rootNode, setRootNode] = useState(null);
	const elRef = useCallback(
		node => {
			if (node !== null) {
				setRootNode(node);
				if (setBannerCallback) setBannerCallback(node);
			}
		},
		[setBannerCallback]
	);
	useEffect(() => {
		if (!rootNode || !setBannerCallback) {
			return () => {};
		}
		const options = {
			threshold: 0.5,
		};
		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				setToggle(entry.isIntersecting);
			});
		}, options);
		observer.observe(rootNode);
		return () => {observer.unobserve(rootNode)};
	}, [rootNode]);

	return React.createElement(elementName || 'div', {
		...box,
		className: clsx(classes.root, classes.initTransition, classes.initSlideUp, classes.initFadeIn, classes.initContentTransition, {
			[classes.noCollapsedMargin]: noCollapseMargin,
			[classes.runFadeIn]: toggle,
			[classes.runSlideUp]: toggle,
		}),
		...(minHeight && { style: { minHeight } }),
		ref: elRef
	}, <div className={classes.boxRoot} style={{
		'--path-skew': skew
	} as React.CSSProperties}>{getChildren(content)}</div>);
};

export default ThemeLoader(
	StyleLoader(
		BoxBlock,
		makeStyles(
			theme => ({
				root: {
					
				},
				noCollapsedMargin: {
					overflow: 'hidden',
				},
				boxRoot: {
					'overflow': 'hidden',
				}
			}),
			{ name: 'SWRotatedBoxContext' }
		)
	)
);

