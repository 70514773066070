import React from 'react';
import { DiscussionEmbed } from 'disqus-react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/core/styles';
import DisqusProps from './props';

const DisqusEmbed = (props: DisqusProps) => {
   const { websiteName, pageUrl, blockTitle, objectId } = props;

   const disqusConfig = {
      url: pageUrl,
      identifier: objectId,
      title: blockTitle,
   };

   return (
      <div className={props.classes.root}>
         <DiscussionEmbed shortname={websiteName} config={disqusConfig} />
      </div>
   );
};

export default ThemeLoader(
   StyleLoader(DisqusEmbed, makeStyles(
      theme => ({
         root: {
            padding: theme.spacing(1),
            fontFamily: theme.typography.fontFamily,
            '& a': { color: theme.palette.secondary[theme.palette.type === 'dark' ? 'light' : 'dark'] },
         },
      }),
      { name: 'Disqus' }
   ))
);
