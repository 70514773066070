import React, { useContext, useCallback } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import getChildren from '../../utils/children';
import { useLinkAttributes } from '../../utils/linkContext';
import clsx from 'clsx';
import SWFakeButtonProps from './props';

const ButtonBlock = ({ iconButton, button, classes, content }: SWFakeButtonProps, ref: React.Ref<any>) => {
	let { buttonRoot, buttonLabel, ...tc } = classes;
	tc.root = clsx(tc.root, { [buttonRoot]: iconButton });
	tc.label = clsx(tc.label, { [buttonLabel]: iconButton });
	// tabIndex == -1 because a fake button isn't part of the tab order: it's meant to present the button UI, but it's
	// style is entirely presentation
	return (
		<Button ref={ref} component='div' {...button} classes={tc} tabIndex={-1} role='presentation'>
			{getChildren(content)}
		</Button>
	);
};

export default ThemeLoader(
	StyleLoader(
		ButtonBlock,
		makeStyles(
			theme => ({
				root: {},
				label: {},
				buttonRoot: {
					textTransform: 'none',
				},
				buttonLabel: {
					'& > [class*=MuiIcon-root]:first-child': {
						marginLeft: theme.spacing(-1),
						marginRight: theme.spacing(1),
					},
					'& > [class*=MuiIcon-root]:last-child': {
						marginLeft: theme.spacing(1),
						marginRight: theme.spacing(-1),
					},
				},
			}),
			{ name: 'SWFakeButton' }
		)
	)
);
