import React, { useCallback } from 'react';
import { useBreakpoint } from '@sightworks/block';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';
import clsx from 'clsx';
import { FieldProps } from '../../registry';

const TextArea = ({ dispatch, id, error, title, classes, value, hideTitle }: FieldProps) => {
	const callback = useCallback(event => {
		dispatch({ action: 'setField', data: { [id]: event.target.value } });
	}, [ dispatch, id ]);
	const breakpoint = useBreakpoint();
	const x: Partial<TextFieldProps> = {};
	if (error) {
		x.error = true;
		x.helperText = "Please fill out this field.";
	}

	let labelProp = hideTitle ? { InputProps: { style: { padding: '12px 10px' } } } : { label: title };

	return (
		<MuiTextField multiline rows={2} rowsMax={100} variant='filled' {...labelProp} className={clsx(classes.field, classes.textArea)} value={value} onChange={callback} inputProps={{ 'aria-label': title }} {...x}/>
	);
}

export default TextArea;
export const Style = {
	textArea: {
		flex: 1
	}
};

