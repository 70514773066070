import { BlockType } from '../block-type';

const Items: Record<string, () => BlockType> = {};
if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
	Items['shared-content'] = () => require('./shared-content/index').default;
	Items['blockPointer'] = () => require('./blockPointer/index').default;
	Items['insertion-point'] = () => require('./insertion-point/index').default;
	Items['component'] = () => require('./component/index').default;
	Items['catalog-content'] = () => require('./catalog-content/index').default;
}

export default Items;
