import { BlockType } from '../block-type';

const Items: Record<string, () => BlockType> = {
	banner: () => require('./banner/index').default,
	button: () => require('./button/index').default,
	card: () => require('./card/index').default,
	details: () => require('./details/index').default,
	summary: () => require('./summary/index').default,
	'nav-button': () => require('./nav-button/index').default,
	tile: () => require('./tile/index').default,
	'share-fab': () => require('./share-fab/index').default,
	download: () => require('./download/index').default,
	'fake-button': () => require('./fake-button/index').default
}

export default Items;
