import React, { useCallback } from 'react';
import { useBreakpoint } from '@sightworks/block';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import DropdownBody from './body';

const DropdownField = ({ dispatch, id, error, title, classes, value, content, status }: DropdownBody) => {
	const callback = useCallback(event => {
		dispatch({ action: 'setField', data: { [id]: event.target.value } });
	}, [ dispatch, id ]);
	const breakpoint = useBreakpoint();
	const x: Partial<TextFieldProps> = {};
	if (error) {
		x.error = true;
		x.helperText = "Please fill out this field.";
	}
	const Item = breakpoint == 'xs' ? 'option' : MenuItem;

	return (
		<TextField select SelectProps={{ native: breakpoint == 'xs' }} variant='filled' label={title} className={clsx(classes.field, classes.dropdown)} value={value} onChange={callback} disabled={status != 'READY'} {...x}>
			{content.map(value => <Item key={value} value={value}>{value}</Item>)}
		</TextField>
	);
}

export default DropdownField;
export const Style = {
	dropdown: {
		flex: 1
	}
};
