import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FieldProps } from '../../registry';
import SpacerBody from './body';

const SpacerField = ({ classes, size }: SpacerBody) => <div style={{ height: size }}/>

export default SpacerField;
export const Style = {}

