import React from 'react';
import getChildren from '../../utils/children';
import PaginatorContext from './context';
import PaginatorProps from './props';

const PaginatorContainerBlock = (props: PaginatorProps, ref: React.Ref<any>) => {
	const { content } = props;
	return <PaginatorContext {...props}>{getChildren(content)}</PaginatorContext>;
};

export default PaginatorContainerBlock;
