import React, { Component, useState, useEffect } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import AppBar from '@material-ui/core/AppBar';
import getChildren from '../../utils/children';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/styles';
import * as Portal from '../../utils/portal';
import AppBarProps from './props';

const AppBarBlock = ({ appBar, classes, content }: AppBarProps, ref: React.Ref<any>) => (
	<AppBar {...appBar} elevation={0} classes={classes} ref={ref}>
		<Portal.Wrapper>
			<Paper elevation={2} square={appBar.square || false} style={{ zIndex: 1150 }}>
				{getChildren(content)}
			</Paper>
		</Portal.Wrapper>
	</AppBar>
);

export default ThemeLoader(StyleLoader(AppBarBlock));

