import React from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import Toolbar from '@material-ui/core/Toolbar';
import getChildren from '../../utils/children';
import ToolbarProps from './props';

let ToolbarBlock = ({ content, toolbar, classes }: ToolbarProps, ref: React.Ref<any>) => {
	return (
		<Toolbar ref={ref} classes={classes} {...toolbar}>
			{getChildren(content)}
		</Toolbar>
	);
};

export default ThemeLoader(StyleLoader(ToolbarBlock));


