import { makeStyles } from '@material-ui/core/styles';
import { StyleLoader } from '@sightworks/theme';
import clsx from 'clsx';
import React from 'react';
import Typography from '../../components/Typography';
import ArticleQuoteProps from './props';

const QuoteBlock = ({ classes, quote, name, title }: ArticleQuoteProps, ref: React.Ref<any>) => {
	return (
		<div className={classes.root} ref={ref}>
			<Typography variant="h3" align="center" className={classes.quote}>
				{`"${quote}"`}
			</Typography>
			{name && (
				<Typography variant="h5" align="center" className={classes.name}>
					{name}
				</Typography>
			)}
			{title && (
				<Typography variant="body2" align="center" className={classes.title}>
					{title}
				</Typography>
			)}
		</div>
	);
};

const Styles = makeStyles(
	theme => ({
		root: {
			// padding: theme.spacing(8),
		},
		quote: {},
		name: {
			paddingTop: theme.spacing(2),
			marginBottom: theme.spacing(1),
		},
		title: {},
	}),
	{ name: 'SWArticle-Quote' }
);

export default StyleLoader(QuoteBlock, Styles);
