import React, { useContext, createContext, useMemo } from 'react';
import * as Registry from '../registry';

type NavigatorContextType = {
	next: NavigatorContextType,
	navigate: (href: string) => Promise<void>;
	navigator: (href: string) => Promise<boolean>;
}

const NavigatorContext = createContext<NavigatorContextType>({
	next: null,
	async navigate(href: string) { Registry.update(href); return; },
	async navigator(href: string): Promise<boolean> { await this.navigate(href); return true; }
});

type NavigatorProps = React.PropsWithChildren<{
	navigate: (href: string) => Promise<boolean>
}>;

const NavigatorProvider = ({ navigate, children }: NavigatorProps) => {
	let currentContext = useContext(NavigatorContext);
	let nextContext = useMemo<NavigatorContextType>(() => {
		let r = {
			next: currentContext,
			navigator: navigate,
			async navigate(href: string): Promise<void> {
				let self = this;
				while (self) {
					if (await self.navigator(href)) return;
					self = self.next;
				}
			}
		}
		return r;
	}, [ currentContext, navigate ]);
	return <NavigatorContext.Provider value={nextContext}>{children}</NavigatorContext.Provider>
}

export default NavigatorProvider;
export { NavigatorContext as Context };

