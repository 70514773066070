import field, { Style } from './field';
import type DisplayedTextQuestionRecordType from './question';

let description: typeof import('./description').default;
let DisplayedTextQuestionRecord: typeof import('./question').default;
export interface DisplayedTextQuestionRecord extends DisplayedTextQuestionRecordType {};
let title: (record: DisplayedTextQuestionRecord) => Promise<string>;
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
	DisplayedTextQuestionRecord = require('./question').default;
	title = async (record: DisplayedTextQuestionRecord): Promise<string> => `Text: ${record.text}`;
}

export default {
	field,
	style: Style,
	description,
	title,
	isAnswerable: false,
	question: DisplayedTextQuestionRecord
}
