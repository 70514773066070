import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FieldProps } from '../../registry';
import IndentBody from './body';
import { QuestionType } from '../../registry';

const IndentField = ({
    classes, size, contents,
    state, dispatch
}: IndentBody) => {
    const QuestionTypes: Record<string, QuestionType> = require('../index').default;
    return contents.length ? <div className={classes.root} style={{ paddingLeft: size }}>{
        contents.map((v, i) => React.createElement(QuestionTypes[v.type].field, {
            state,
            error: state?.error && 'question' in state?.error && state.error.question == v.id ? state.error : null,
            status: state.status,
            key: v.id,
            classes,
            dispatch,
            ...v,
            value: state.formContents[v.id] || ''
        }))
    }</div> : null;
}

export default IndentField;
export const Style = {}

