import React, { useCallback } from 'react';
import ChecklistBody from './body';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import ChecklistItem from '../checklist-item/field';
import { QuestionType } from '../../registry';
import type { FormState } from '../../block/form/block';
import { useBreakpoint } from '@sightworks/block';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';

const ChecklistField = ({ state, dispatch, id, error, title, classes, value, options, status, itemType }: ChecklistBody) => {
    let varr = String(value).split(',').filter(v => v);
    let extraOptions = options.filter(v => varr.includes(v.id)).map(v => v.contents).reduce((a, b) => [...a, ...b], []);
	const callback = useCallback(event => {
		dispatch({ action: 'setField', data: { [id]: event.target.value } });
	}, [ dispatch, id ]);
    const QuestionTypes: Record<string, QuestionType> = require('../index').default;
    const breakpoint = useBreakpoint();
	const x: Partial<TextFieldProps> = {};
	if (error) {
		x.error = true;
		x.helperText = "Please fill out this field.";
	}
    let DropdownType = breakpoint == 'xs' ? 'option' : MenuItem;

    return <>
        {itemType == 'dropdown' ? (
            <TextField select SelectProps={{ native: breakpoint == 'xs' }} variant='filled' label={title} className={clsx(classes.field, classes.dropdown)} value={value} onChange={callback} disabled={status != 'READY'} {...x}>
                {options.map((v, i) => <DropdownType key={v.id} value={v.id}>{v.title}</DropdownType>)}
            </TextField>
        ) : (
            <FormControl component="fieldset" className={clsx(classes.field, {
                [classes.checklist]: itemType == 'checklist',
                [classes.radiogroup]: itemType == 'radio'
            })}>
                <FormLabel className={classes.checklistLabel} component="legend">{title}</FormLabel>
                <FormGroup className={classes.checklistGroup}>
                    {options.map((v, i) => <ChecklistItem itemType={itemType} dispatch={dispatch} fieldId={id} error={error} value={value} status={status} key={i} {...v}/>)}
                </FormGroup>
            </FormControl>
        )}
        {extraOptions.length ? <div style={itemType == 'dropdown' ? void 0 : { marginTop: '-12px' }} className={classes.root}>
            {extraOptions.map((v, i) => React.createElement(QuestionTypes[v.type].field, {
                state,
                error: (state?.error && 'question' in state.error && state.error.question == v.id) ? state.error : null,
                status: state.status,
                key: v.id,
                classes,
                dispatch,
                ...v, 
                value: state.formContents[v.id] || ''
            }))}
        </div> : null}
	</>
}

export default ChecklistField;

export const Style = {
    checklist: {},
    radiogroup: {
        '& $checklistGroup': {
            flexDirection: 'row'
        }
    },
    checklistLabel: {},
    checklistGroup: {}
};
