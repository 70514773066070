import React from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import Typography from '../../components/Typography';
import PlaintextProps from './props';

const PlaintextBlock = ({ classes, text, omitIfEmpty }: PlaintextProps, ref: React.Ref<any>) => (
	(!omitIfEmpty || text.children) ? <Typography {...text} classes={classes} ref={ref}/> : null
);

export default ThemeLoader(StyleLoader(PlaintextBlock));
