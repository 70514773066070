import PageBlock, { ThemedPage } from './special/page/block';
import { makeStyles } from '@material-ui/styles';
import { ThemeOptions } from '@material-ui/core/styles';

import { BlockType, BlockTypeList, BlockTypeLoader } from './block-type';
import { BlockPropsBase } from '.';
import Record from './record';
import { createMuiTheme, responsiveFontSizes, ExtendedTheme, ExtendedThemeOptions } from '@sightworks/theme';
import { EventEmitter } from '@sightworks/util';

class EventMap<K, V> extends EventEmitter implements Map<K, V> {
	private _map: Map<K, V>;
	mapArray: [K, V][] = [];

	constructor(entries?: readonly (readonly [K, V])[] | null) {
		super();
		this._map = new Map<K, V>(entries);
		if (entries) {
			for (let i = 0; i < entries.length; ++i) {
				this.mapArray[i] = [entries[i][0], entries[i][1]];
			}
		}
	}

	clear() {
		if (this.eventNames().includes('delete')) {
			for (let key of this._map.keys()) {
				this.emit('delete', key, this._map.get(key));
				this._map.delete(key);
				this.mapArray.splice(this.mapArray.findIndex(v => v[0] == key), 1)
			}
		} else {
			this._map.clear();
			this.mapArray = [];
		}
	}

	delete(k: K): boolean {
		if (this._map.has(k)) {
			this.emit('delete', k, this._map.get(k));
			this.mapArray.splice(this.mapArray.findIndex(v => v[0] == k), 1);
		}
		return this._map.delete(k);
	}

	forEach(callbackfn: (value: V, key: K, map: EventMap<K, V>) => void, thisArg: any = this): void {
		this._map.forEach((value, key) => {
			callbackfn.call(thisArg, value, key, this);
		});
	}

	get(key: K): V | undefined {
		return this._map.get(key);
	}

	has(key: K): boolean {
		return this._map.has(key);
	}

	set(key: K, value: V): this {
		if (this._map.has(key)) {
			this.emit('change', key, this._map.get(key), value);
			this.mapArray.find(v => v[0] == key)[1] = value;
		} else {
			this.emit('add', key, value);
			this.mapArray.push([ key, value ]);
		}
		this._map.set(key, value);
		return this;
	}

	get size() {
		return this._map.size;
	}

	*[Symbol.iterator](): IterableIterator<[K, V]> {
		yield* this._map;
	}

	*entries(): IterableIterator<[K, V]> {
		yield* this._map.entries();
	}

	*keys(): IterableIterator<K> {
		yield* this._map.keys();
	}

	*values(): IterableIterator<V> {
		yield* this._map.values();
	}

	readonly [Symbol.toStringTag]: 'EventMap';
}

class GeneratorEventMap<K, V> extends EventMap<K, V> {
	private _generator: (key: K, map: GeneratorEventMap<K, V>) => V;
	constructor(
		entries?: readonly (readonly [K, V])[] | null,
		generator?: (key: K, map: GeneratorEventMap<K, V>) => V
	) {
		super(entries);
		this._generator = generator;
	}
	get(key: K): V {
		if (!super.has(key)) {
			let value = this._generator(key, this);
			if (value) {
				this.set(key, value);
			}
			return value;
		}
		return super.get(key);
	}

	has(key: K): boolean {
		if (!super.has(key)) {
			let value = this._generator(key, this);
			if (value) {
				this.set(key, value);
				return true;
			}
			return false;
		} else {
			return true;
		}
	}
}

type BlockTypeWrapper = {
	loader?: BlockTypeLoader;
	value?: BlockType;
}
const Registry = new GeneratorEventMap<string, BlockTypeWrapper>(
	null,
	(key: string, map: GeneratorEventMap<string, BlockTypeWrapper>): BlockTypeWrapper => {		
		// console.log(`Looking for generator of ${key}...`);
		for (let i = 0; i < map.mapArray.length; i++) {
			var valueWrapper = map.mapArray[i][1];
			if (!valueWrapper.value) valueWrapper.value = valueWrapper.loader();
			let value = valueWrapper.value;
			if (value.generatorPrefix && value.generator) {
				// console.log(`Checking generator: ${value.generatorPrefix}`);
				if (key.startsWith(value.generatorPrefix)) {
					let k = key.substring(value.generatorPrefix.length);
					let v = map.get(k);
					if (v) {
						if (!v.value) v.value = v.loader();
						return { loader() { return value.generator(v.value) } };
					}
				}
			}
		}
		return null;
	}
);

type StyleSheet = ReturnType<typeof makeStyles>;
type RawTheme = ExtendedTheme;
type ThemeGen = (theme: ExtendedThemeOptions | {}) => ExtendedTheme;
export type Theme = ExtendedThemeOptions | ThemeGen;
type ThemeItem = {
	theme: Theme;
	responsiveFonts?: boolean;
};
type StyleItem = {
	title: string;
	style: any;
	muiStyles?: StyleSheet
};

const NamedStyles = new Map<string, StyleItem>();
export const NamedThemes = new Map<string, ThemeItem>();

let adminAddBlocks: typeof import('./registry-admin').addBlocks;
if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
    adminAddBlocks = require('./registry-admin').addBlocks;
}

export function addBlocks(types: BlockTypeList) {
	let tl: Iterable<[ string, BlockType | BlockTypeLoader ]>;

	if (!(Symbol.iterator in types)) {
		tl = Object.entries(types);
	} else {
		tl = types as Iterable<[ string, BlockType ]>;
	}
	for (let [ typeName, type ] of tl) {
		if (typeof type == 'function')
			Registry.set(typeName, { loader: type });
		else
			Registry.set(typeName, { value: type });
    }
    if (adminAddBlocks) {
        adminAddBlocks(types);
    }
}

export function removeBlockType(...types: string[]) {
	types.forEach(t => Registry.delete(t))
}

export function *allBlocks(): IterableIterator<[ string, BlockType ]> {
	for (let [blockType, block] of Registry) {
		yield [blockType, block.value ?? (block.value = block.loader())];
	}
}

export function getBlock(props: BlockPropsBase, raw = false) {
	let b = Registry.get(props.type);
	return (raw ? (b.value ?? (b.value = b.loader())).rawComponent : null) || (b.value ?? (b.value = b.loader())).component;
}

/** 
 * Given the props for a single block, retrieve it's children.
 *
 * @param {object} props The block properties. This must have a property 'type'.
 * @return {Array.<object>} The props of the child blocks.
 */
import { flattenChildren } from './utils/children';
export function getChildren(props: BlockPropsBase): BlockPropsBase[] {
	if (props.merge) return flattenChildren(props.content);
	let t = Registry.get(props.type);
	if (!t) {
		console.log("No block type " + props.type + "?");
		throw new Error("No block type found: " + props.type);
	}
	return (t.value ?? (t.value = t.loader())).getChildren(props);
}

/**
 * Retrieve the entire block type from the registry based on it's record.
 *
 * @param {Record} record The block record from the '<channelKey>_blocks' app.
 * @return {BlockType} The block type
 */
export function getBlockInfo(record: Record) {
	if (!record.child_type) {
		console.trace(record);
	}
	let value = Registry.get(record.child_type);
	if (value) {
		if (value.value) return value.value;
		return value.value = value.loader();
	}
	return null;
}

export function getBlockType(id: string) {
	let value = Registry.get(id);
	if (value) {
		if (value.value) return value.value;
		return value.value = value.loader();
	}
	return null;
}

export function getTheme() {
	return ThemedPage.defaultTheme;
}

export function setTheme(t: any) {
	// console.log(`Set theme: `, t);
	ThemedPage.defaultTheme = t;
}

export function addNamedStyles(items: Iterable<[ string, any ]> | { [key: string]: any }) {
	if (items && typeof items == 'object') {
		if (!(Symbol.iterator in items)) items = Object.entries(items);
	}

	for (let [ id, style ] of items as Iterable<[string, any]>) {
		NamedStyles.set(id, style);
	}
}

export function *allNamedStyles() { yield* NamedStyles[Symbol.iterator](); }
export function getNamedStyle(name: string) { return NamedStyles.get(name); }

const placeholderStyle = makeStyles({ root: {} }, { name: 'Placeholder' });

export function makeNamedStyle(name: string) {
	let s = getNamedStyle(name);
	if (s) {
		if (!s.muiStyles) {
			s.muiStyles = makeStyles(s.style, { name });
		}
		return s.muiStyles;
	}
	return placeholderStyle;
}

export function addNamedThemes(items: Iterable<[string, ThemeItem]> | { [key: string]: ThemeItem }) {
	if (items && typeof items == 'object') {
		if (!(Symbol.iterator in items)) items = Object.entries(items);
	}

	for (let [ id, theme ] of items as Iterable<[string, ThemeItem]>) {
		NamedThemes.set(id, theme);
	}
}

export function *allNamedThemes() { yield* NamedThemes[Symbol.iterator](); }
export function removeNamedTheme(name: string) {
	let th = NamedThemes.get(name);
	if (th) {
		NamedThemes.delete(name);
		for (let [ currentTheme, cache ] of Cache) {
			if (cache.has(th)) {
				cache.delete(th);
			}
		}
	}
}
export function getNamedTheme(name: string) { return NamedThemes.get(name); }

const Cache = new Map<ExtendedTheme, Map<ThemeItem, ExtendedTheme>>();
export function makeNamedTheme(name: string, currentTheme: ExtendedTheme): ExtendedTheme {
	let tgt = getNamedTheme(name);
	if (tgt) {
		let c = Cache.get(currentTheme);
		if (!c) {
			c = new Map<ThemeItem, ExtendedTheme>();
			Cache.set(currentTheme, c);
		}
		if (c.has(tgt)) {
			return c.get(tgt);
		}
		let out = tgt.theme;
		let v: ExtendedThemeOptions;
		if (typeof tgt.theme == 'function') v = (tgt.theme as ThemeGen)(currentTheme || {});
		else v = out as ExtendedThemeOptions;
		let t = createMuiTheme(v);
		if (tgt.responsiveFonts)
			t = responsiveFontSizes(t);
		c.set(tgt, t);
		return c.get(tgt);
	}
	return currentTheme;
}

type UpdaterFn = (url: string) => any;
let updater: UpdaterFn = null;

export function update(href: string) {
	if (updater) updater(href);
	else location.href = href;
}

export function setUpdater(f: UpdaterFn) {
	updater = f;
}

export function *getGenerators(): Iterable<[ string, BlockType ]> {
	for (let [id, typeC] of Registry) {
		let type = typeC.value ?? (typeC.value = typeC.loader());
		if (type.generator && type.generatorPrefix) {
			yield [id, type];
		}
	}
}
const on = Registry.on.bind(Registry);
const off = Registry.off.bind(Registry);

export { on, off };
