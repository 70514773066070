import React from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import Typography from '../../components/Typography';
import PlaintextProps from './props';
import getChildren from '../../utils/children';

const PlaintextBlock = ({ classes, text, content }: PlaintextProps, ref: React.Ref<any>) => (
	<Typography {...text} classes={classes} ref={ref}>{getChildren(content)}</Typography>
);

export default ThemeLoader(StyleLoader(PlaintextBlock));
