import React from 'react';
import { GoogleMap, MarkerClusterer, LoadScript, Marker } from '@react-google-maps/api';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import GoogleMapsProps from './props';

const grayScaleStyle: google.maps.MapTypeStyle[] = [
	{
		featureType: "water",
		elementType: "geometry",
		stylers: [
			{
				color: "#e9e9e9"
			},
			{
				lightness: 17
			}
		]
	},
	{
		featureType: "landscape",
		elementType: "geometry",
		stylers: [
			{
				color: "#f5f5f5"
			},
			{
				lightness: 20
			}
		]
	},
	{
		featureType: "road.highway",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#ffffff"
			},
			{
				lightness: 17
			}
		]
	},
	{
		featureType: "road.highway",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#ffffff"
			},
			{
				lightness: 29
			},
			{
				weight: 0.2
			}
		]
	},
	{
		featureType: "road.arterial",
		elementType: "geometry",
		stylers: [
			{
				color: "#ffffff"
			},
			{
				lightness: 18
			}
		]
	},
	{
		featureType: "road.local",
		elementType: "geometry",
		stylers: [
			{
				color: "#ffffff"
			},
			{
				lightness: 16
			}
		]
	},
	{
		featureType: "poi",
		elementType: "geometry",
		stylers: [
			{
				color: "#f5f5f5"
			},
			{
				lightness: 21
			}
		]
	},
	{
		featureType: "poi.park",
		elementType: "geometry",
		stylers: [
			{
				color: "#dedede"
			},
			{
				lightness: 21
			}
		]
	},
	{
		elementType: "labels.text.stroke",
		stylers: [
			{
				visibility: "on"
			},
			{
				color: "#ffffff"
			},
			{
				lightness: 16
			}
		]
	},
	{
		elementType: "labels.text.fill",
		stylers: [
			{
				saturation: 36
			},
			{
				color: "#333333"
			},
			{
				lightness: 40
			}
		]
	},
	{
		elementType: "labels.icon",
		stylers: [
			{
				visibility: "off"
			}
		]
	},
	{
		featureType: "transit",
		elementType: "geometry",
		stylers: [
			{
				color: "#f2f2f2"
			},
			{
				lightness: 19
			}
		]
	},
	{
		featureType: "administrative",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#fefefe"
			},
			{
				lightness: 20
			}
		]
	},
	{
		featureType: "administrative",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#fefefe"
			},
			{
				lightness: 17
			},
			{
				weight: 1.2
			}
		]
	}
];

const GoogleMaps = (props: GoogleMapsProps, ref: React.Ref<any>) => {
	const { classes, googleKey, googleId, cords, center, zoom } = props;
	const locations = JSON.parse(cords);
	const {lat: centerLat, long: centerLong} = center;
	return (
		<LoadScript
			id="script-loader"
			googleMapsApiKey={"AIzaSyBX1z5nvjcjzyxSMT-QCVS3ERu6Y3iNSb0"}
			language="en"
			region="EN"
			version="weekly"
		>
			<GoogleMap
				id="marker-example"
				mapContainerStyle={{
					height: "100%",
					width: "100%"
				}}
				options={{ styles: grayScaleStyle, disableDefaultUI: true }}
				zoom={Number(zoom)}
				center={{ lat: Number(centerLat), lng: Number(centerLong) }}
			>
				<MarkerClusterer
					options={{
						imagePath:
							"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
						styles: []
					}}
				>
					{clusterer =>
						locations.map((location: any, i: number) => (
							<Marker key={i} position={location} clusterer={clusterer} />
						))
					}
				</MarkerClusterer>
			</GoogleMap>
		</LoadScript>
	);
};

export default ThemeLoader(
	StyleLoader(
		GoogleMaps,
		makeStyles(
			theme => ({
				root: {},
			}),
			{ name: 'SwGoogleMaps' }
		)
	)
);
