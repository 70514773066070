import React from 'react';
import Typography from '@material-ui/core/Typography';
import DisplayedTextQuestion from './body';

const DisplayedTextField = ({ classes, text }: DisplayedTextQuestion) => <Typography className={classes.displayedText} variant="body1">{text}</Typography>;

export default DisplayedTextField;
export const Style = {
	displayedText: {}
};

