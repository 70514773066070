import { Registry } from '@sightworks/block';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { Component, Fragment, useEffect, useState } from 'react';
import { StyleLoader, ThemeLoader, ExtendedTheme } from '@sightworks/theme';
import { ThemeOptions, useTheme } from '@material-ui/core/styles';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import PageProps from './props';
import { Theme } from '../../registry';
import PageContext from './context';
import PauseWrapper from '../../utils/pauseContext';
const Page = (props: PageProps, ref: React.Ref<any>) => {
	let theme = useTheme();
	return (
		<>
			<PageContext.Provider value={props}>
				<PauseWrapper>
					{React.createElement(Registry.getBlock(props.root), { ...props.root, classes: props.classes, className: props.className, ref, rootComponent: true })}
				</PauseWrapper>
			</PageContext.Provider>
		</>
	);
};

type ThemeProps = PageProps;

const ThemedPage = ThemeLoader(Page);
let UseTheme = ({ theme, ...rest }: PageProps & { theme: ExtendedTheme }, ref: React.Ref<any>) => {
	const [ ThemedPage, setThemedPage ] = useState<React.FC<ThemeProps> & { defaultTheme: any }>(ThemeLoader(Page, theme));
	useEffect(() => {
		if (ThemedPage.defaultTheme != theme) {
			setThemedPage(ThemeLoader(Page, theme));
		}
	}, [ theme ]);
	return <ThemedPage {...rest} ref={ref}/>;
};
UseTheme = React.forwardRef(UseTheme);

const BasePage = ({ useNamedTheme, ...props }: PageProps, ref: React.Ref<any>) => {
	if (useNamedTheme) {
		let bt = Registry.makeNamedTheme(useNamedTheme, null);
		if (bt) {
			return <UseTheme theme={bt} {...props} ref={ref}/>
		}
	}
	return <ThemedPage {...props} ref={ref}/>
}

export default React.forwardRef(BasePage);
export { ThemedPage };

