import React, { useState, useEffect, useCallback } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import getChildren from '../../utils/children';
import 'intersection-observer'; // for pollyfill
import BoxProps from './props';

const BoxBlock = ({ box, elementName, noCollapseMargin, minHeight, classes, content }: BoxProps, ref: React.Ref<any>) => {
	const [rootNode, setRootNode] = useState(null);
	const elRef = useCallback(
		node => {
			if (node !== null) {
				setRootNode(node);
			}
		},
		[]
	);
	useEffect(() => {
		if (!rootNode) {
			return () => {};
		}

		let ro: ResizeObserver;
		let rf = () => {
			rootNode.style.setProperty('--content-height', rootNode.scrollHeight + 'px');
			rootNode.style.setProperty('--content-width', rootNode.scrollWidth + 'px');
		}
		if (window.ResizeObserver) {
			ro = new ResizeObserver(rf);
			ro.observe(rootNode)
		} else {
			window.addEventListener('resize', rf);
		}

		return () => {
			if (ro) ro.unobserve(rootNode);
			else window.removeEventListener('resize', rf);
		};
	}, [rootNode]);

	return React.createElement(elementName || 'div', {
		...box,
		className: clsx(classes.root, {
			[classes.noCollapsedMargin]: noCollapseMargin,
		}),
		...(minHeight && { style: { minHeight } }),
		ref: elRef
	}, getChildren(content));
};

export default ThemeLoader(
	StyleLoader(
		BoxBlock,
		makeStyles(
			theme => ({
				root: {},
				noCollapsedMargin: {
					overflow: 'hidden',
				},
			}),
			{ name: 'SWSizerBox' }
		)
	)
);

