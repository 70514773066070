import React, { useContext, useState, useCallback, useEffect, useRef } from 'react';
import { Context } from '../gallery-container/context';
import clsx from 'clsx';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
// import RSV from 'react-swipeable-views';
import Typography from '../../components/Typography';
import { BlockPropsBase } from '../..';
import { Theme } from '@material-ui/core';
import Carousel, { CarouselComponent } from '../../containers/sw-carousel/carousel';

let NoOp = () => {};

/* FIXME */
type ExtraProps = {
	showCaption?: any;
	title?: string;
	summary?: string;
}

let CurrentGalleryItem = (props: BlockPropsBase & ExtraProps) => {
	let context = useContext(Context);
	let item = context.state.elements[context.state.selectedIndex];
	let [ entered, setEntered ] = useState([context.state.selectedIndex]);
	useEffect(() => {
		if (entered.indexOf(context.state.selectedIndex) == -1)
			setEntered([ ...entered, context.state.selectedIndex ]);
	}, [ context.state.selectedIndex ]);
	
	let goNext = useCallback(() => context.dispatch({ type: 'set-selected-index', selectedIndex: context.state.selectedIndex + 1 }), [context]);
	let goBack = useCallback(() => context.dispatch({ type: 'set-selected-index', selectedIndex: context.state.selectedIndex - 1 }), [context]);
	let swipe = useCallback((index) => context.dispatch({ type: 'set-selected-index', selectedIndex: index }), [ context ]);
	let { classes } = props;
	let title = props.title ?? item.title;
	let summary = props.summary ?? item.summary;

	let itemEntered: CarouselComponent['props']['carouselItemEntered'] = useCallback((carousel, child, index) => {
		swipe(index);
	}, [swipe]);

	let carousel: React.RefObject<CarouselComponent> = React.createRef();
	useEffect(() => {
		carousel.current?.cycleToItem(context.state.selectedIndex);
	}, [context.state.selectedIndex]);

	// let imgSrc = item.src;

	let carouselClasses = React.useMemo<CarouselComponent['props']['classes']>(() => {
		return {
			carouselContainer: classes.carouselContainer,
			carouselItem: classes.carouselItem
		}
	}, []);

	let root = (
		<div className={clsx(classes.root, { [classes.withCaption]: props.showCaption && (title || summary) })}>
			<Carousel infinite={true} carouselItemEntered={itemEntered} ref={carousel} classes={carouselClasses} outside={true} outline={true}>
				{context.state.elements.map((item, index) => (
					<div className={classes.contentItem} key={item.id ?? index}>
						<div className={classes.contentInner}>
							{item.embeddedObject ? (
								context.state.selectedIndex === index && (<div title={props.title} className={classes.embedObject} dangerouslySetInnerHTML={{ __html: item.embeddedObject.embedCode }}/>)
							) : (
								<div className={classes.imageWrapper}>
									<img className={clsx(classes.image)} alt={item.title} src={item.src} />
								</div>
							)}
							{props.showCaption && <div className={classes.caption}>
								{item.title && <Typography variant='h4' className={classes.title}>{item.title}</Typography>}
								{item.summary && <Typography variant='body1' className={classes.summary}>{item.summary}</Typography>}
								{item.credits && <Typography component={'div'} variant='caption' className={classes.captionText}>{item.credits}</Typography>}
							</div>}
						</div>	
					</div>
				))}
			</Carousel>
		</div>
	);

	return root;
}

let Styles = makeStyles((theme: Theme) => ({
	carouselContainer: {
		width: '100%',
	},
	carouselItem: {
		flex: '0 0 100%'
	},
	root: {
		outline: 0,
		display: 'flex',
		flexDirection: 'row',
		flex: '1 1 auto',
		height: 'calc(100vh - 286px)',
		[theme.breakpoints.down('sm')]: {
			marginLeft: -16,
			marginRight: -16,
			paddingLeft: 45,
			paddingRight: 45
		}
	},
	buttonWrapper: {
		flex: '0 0 auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	button: {
		flex: '0 0 auto',
	},
	previous: {},
	next: {},
	content: {
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'column',
		'& > .react-swipeable-view-container': {
			flex: '1 1 auto',
			width: '100%',
			'& > div': {
				display: 'flex',
				flexDirection: 'column'
			}
		}
	},
	contentInner: {
		flex: '1 1 auto',
		position: 'relative',
		display: 'grid',
		alignItems: 'flex-start',
		justifyContent: 'center',
		gridTemplateRows: '1fr max-content',
		width: '100%',
		gridTemplateColumns: '100%',
		textAlign: 'center'
	},
	caption: {
		flex: '0 0 auto',
		marginTop: theme.spacing(2)
	},
	captionText: {
		fontStyle: 'italic'
	},
	loading: {},
	withCaption: {},
	skeleton: {
		borderRadius: theme.shape.borderRadius,
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0
	},
	imageWrapper: {
		position: 'relative',
		height: '100%'
	},
	image: {
		maxWidth: 'unset',
		maxHeight: 'unset',
		objectFit: 'contain',
		borderRadius: theme.shape.borderRadius,
		'&$loading': {
			display: 'none !important'
		},
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		width: '100%',
		height: '100%'
	},
	title: {
		// color: theme.palette.primary.contrastText,
		// marginTop: theme.spacing(1)
	},
	summary: {
		// color: theme.palette.primary.contrastText,
		width: '70%',
		margin: 'auto',
		// marginTop: theme.spacing(1)
	},
	embedObject: {
		position: 'absolute',
		top: '5vmin',
		left: '5vmin',
		right: '5vmin',
		bottom: '5vmin',
		'& > *': {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			width: '100%',
			height: '100%'
		}
	},
	contentOut: {},
	contentItem: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1 1 auto'
	}
}), { name: 'SwGalleryCurrentItem' });

export default ThemeLoader(StyleLoader(CurrentGalleryItem, Styles));
