import React, { useState, useEffect, useCallback, useContext } from 'react';
import { StyleLoader, ThemeLoader, CustomBackgroundContext } from '@sightworks/theme';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import getChildren from '../../utils/children';
import 'intersection-observer'; // for pollyfill
import RotatedBoxProps from './props';

const RotatedBoxBlock = ({
	minHeight,
	classes,
	content
}: RotatedBoxProps, ref: React.Ref<any>) => {
	const backgroundContext = useContext(CustomBackgroundContext);
	return (
		<div className={classes.root}>
			<div className={clsx(classes.backgroundContainer, backgroundContext?.targetClasses.root)}>
				{backgroundContext?.video || null}
			</div>
			<div className={classes.innerContainer} {...(minHeight && { style: { minHeight } })}>
				{getChildren(content)}
			</div>
		</div>
	);
}

export default ThemeLoader(
	StyleLoader(
		RotatedBoxBlock,
		makeStyles(
			theme => ({
				root: {
					boxSizing: 'border-box',
					position: 'relative',
					'--path-skew-top': 'var(--path-skew)',
					'--path-skew-bottom': 'var(--path-skew)',
					'&:first-child': {
						'--path-skew-top': '0px'
					},
					'&:last-child': {
						'--path-skew-bottom': '0px'
					},
					marginTop: 'calc(-1 * var(--path-skew-top))'
				},
				backgroundContainer: {
					position: 'absolute',
					top: `0`,
					bottom: `0`,
					left: `0`,
					right: `0`,
					zIndex: 0,
					clipPath: `polygon(0 var(--path-skew-top), 100% 0, 100% calc(100% - var(--path-skew-bottom)), 0 100%)`
				},
				innerContainer: {
					overflow: 'hidden',
					position: 'relative',
					zIndex: 1,
					paddingTop: `var(--path-skew-top)`,
					paddingBottom: `var(--path-skew-bottom)`
				}
			}),
			{ name: 'SWRotatedBox' }
		),
		true
	)
);

