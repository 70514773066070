import React, { useContext, useCallback, useState } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import getChildren from '../../utils/children';
import { useLinkAttributes } from '../../utils/linkContext';
import clsx from 'clsx';
import SWButtonProps from './props';

const ButtonBlock = ({ button, link, classes, content, requiresFocus, requiresFocusMobile }: SWButtonProps, ref: React.Ref<any>) => {
	const lp = useLinkAttributes(link);
	const [isFocused, setFocused] = useState<boolean>(false);
	let { buttonRoot, buttonLabel, ...tc } = classes;
	let c: { 
		onClick?: (typeof lp)['onClick'], 
		onBlur?: React.FocusEventHandler<HTMLElement>,
		onFocus?: React.FocusEventHandler<HTMLElement>,
		onMouseEnter?: React.MouseEventHandler<HTMLElement>,
		onMouseLeave?: React.MouseEventHandler<HTMLElement>
	} = {};
	if (requiresFocus || requiresFocusMobile) {
		c = {
			onClick: (event: React.MouseEvent<HTMLElement>) => {
				let isMobile = window.getComputedStyle(event.currentTarget).getPropertyValue('--mobile').replace(/\s*/, '') == '1';
				let fr = !isMobile ? requiresFocus : requiresFocusMobile;
				if (fr && !isFocused) {
					event.preventDefault();
					return;
				}
				if (lp.onClick) lp.onClick(event);
				return;
			},
			onFocus: () => {
				requestAnimationFrame(() => setFocused(true));
			},
			onBlur: () => {
				requestAnimationFrame(() => setFocused(false))
			},
			onMouseEnter: () => {
				requestAnimationFrame(() => setFocused(true));
			},
			onMouseLeave: () => {
				requestAnimationFrame(() => setFocused(false))
			}
		}
	}
	return (
		<Button ref={ref} {...button} {...lp} {...c} classes={classes}>
			{getChildren(content)}
		</Button>
	);
};

export default ThemeLoader(
	StyleLoader(
		ButtonBlock,
		makeStyles(
			theme => ({
				root: {
					'--mobile': '0',
					[theme.breakpoints.down('sm')]: { '--mobile': '1' }
				},
				label: {}
			}),
			{ name: 'SWButtonContainer' }
		)
	)
);
