import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';
import React, { useContext, useRef, useState, useEffect, useCallback, Fragment, SyntheticEvent } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '../../components/Typography';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { useLinkAttributes } from '../../utils/linkContext';
import ButtonBase from '@material-ui/core/ButtonBase';
import TileProps from './props';
import { number } from 'prop-types';

type TitleTextProps = {
	content: string | any;
	decoration: string;
}
const TitleText = ({ content, decoration }: TitleTextProps) => {
	if (typeof content != 'string') return content;
	if (content.indexOf(',') == -1) return content;
	let [ left, ...right1 ] = content.split(',');
	let right = `,${right1.join(',')}`;
	return <>{left}<span className={decoration}>{right}</span></>;
}

type RefFn<T> = (value: T | null) => void;
type RefTarget<T> = React.MutableRefObject<T> | RefFn<T>;

const TileBlock = (
	{
		id,
		image,
		imageAlt,
		caption,
		captionVariant,
		captionColor,
		title,
		titleVariant,
		titleColor,
		contentText,
		contentVariant,
		contentColor,
		align,
		minContentSize = null,
		minHeight,
		classes,
		link,
		buttonText,
		buttonVariant,
		buttonTextVariant,
		buttonColor,
		buttonSize,
		buttonFullWidth,
		resized,
		shouldResize,
		placeholderImageWidth = '300px',
		placeholderImageHeight = '300px',
		clickable = false,
		textVerticalPosition = 'bottom',
		withIcon = true,
		square = false,
		focusBackground = false,
		stripe = "",
		stripeVariant = "caption",
		stripeColor = "inherit"
	}: TileProps,
	ref: RefTarget<any>
) => {
	// const cx = useContext(Context);
	type StateType = {
		contentNode: HTMLDivElement,
		imageNode: HTMLImageElement,
		imageSize: {
			width: number;
			height: number;
		},
		contentSize: number;
		contentAdjust: number;
		rootNode: HTMLDivElement;
	}
	const [state, setState] = useState<StateType>({
		contentNode: null,
		imageNode: null,
		imageSize: null,
		contentSize: null,
		contentAdjust: 0,
		rootNode: null
	});
	const lp = useLinkAttributes(link);
	const rootNode = useRef(state.rootNode);
	const imageNode = useRef(state.imageNode);
	const contentNode = useRef(state.contentNode);

	if (!image) {
		// const h = true ? 150 : placeholderImageHeight;
		image = `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" width="${placeholderImageWidth}" height="${placeholderImageHeight}"/>`;
	}

	const [gotRoot] = useState(() => (value: HTMLDivElement) => {
		setState(state => ({ ...state, rootNode: value }));
		rootNode.current = value;
		if (ref) {
			if (typeof ref === 'function') ref(value);
			else ref.current = value;
		}
	});

	const [imageLoaded] = useState<(value: any) => void>(() => (value?: any) => {
		setState(state => {
			console.log(`imageLoaded`, state);
			return {
				...state,
				imageSize: state.imageNode
					? {
						width: state.imageNode.width,
						height: state.imageNode.height,
					}
					: null,
			};
		});
	});

	const [gotImage] = useState(() => (value: HTMLImageElement) => {
		console.log('gotImage: ', value);
		imageNode.current = value;
		setState(state => {
			if (value && value.width && (!state.imageSize || state.imageSize.width != value.width)) {
				requestAnimationFrame(imageLoaded);
			}
			return { ...state, imageNode: value };
		});
	});

	const [contentReady] = useState(() => (value: HTMLDivElement) => {
		contentNode.current = value;
		setState(state => {
			const S: StateType = {
				...state,
				contentNode: value,
				contentSize: value ? Math.max(value.offsetHeight, value.scrollHeight) : null,
			};
			if (value != state.contentNode) {
				S.contentAdjust = 0;
				if (value) {
					requestAnimationFrame(() => {
						console.log({
							rect: value.getBoundingClientRect(),
							prevRect: (value.previousSibling as HTMLElement).getBoundingClientRect(),
						});
						const pb = (value.previousSibling as HTMLElement).getBoundingClientRect().bottom;
						const ct = value.getBoundingClientRect().top;
						console.log({ ct, pb, adjust: ct - pb });
						setState(state => ({
							...state,
							contentAdjust: Math.round(ct - pb),
						}));
					});
				}
			}
			return S;
		});
	});

	const debounce = useRef(null);

	const [doResize] = useState(() => () => {
		console.log(`card ${id} resizing`);
		rootNode.current.style.removeProperty('--content-size');
		rootNode.current.style.setProperty('--content-adjust', '0px');
		rootNode.current.style.removeProperty('--hover-space');
		rootNode.current.style.removeProperty('--image-full-size');
		rootNode.current.style.removeProperty('--image-hover-size');

		requestAnimationFrame(() => {
			const stateUpdate: Partial<StateType> = {
				imageSize: null,
				contentSize: contentNode.current ? Math.max(contentNode.current.offsetHeight, contentNode.current.scrollHeight) : null,
				contentAdjust: 0,
			};

			if (imageNode.current) {
				stateUpdate.imageSize = {
					width: imageNode.current.width,
					height: imageNode.current.height,
				};
			}
			if (contentNode.current) {
				stateUpdate.contentAdjust = Math.round(
					contentNode.current.getBoundingClientRect().top -
					(contentNode.current.previousSibling as HTMLElement).getBoundingClientRect().bottom
				);
			}

			if (stateUpdate.contentSize)
				rootNode.current.style.setProperty('--content-size', `${stateUpdate.contentSize}px`);
			if (stateUpdate.contentAdjust)
				rootNode.current.style.setProperty('--content-adjust', `${stateUpdate.contentAdjust}px`);
			if (stateUpdate.contentSize && minContentSize && minContentSize > stateUpdate.contentSize)
				rootNode.current.style.setProperty(
					'--hover-space',
					`${0.5 * (minContentSize - stateUpdate.contentSize)}px`
				);
			if (stateUpdate.imageSize)
				rootNode.current.style.setProperty('--image-full-size', `${stateUpdate.imageSize.height}px`);
			if (stateUpdate.imageSize && stateUpdate.contentSize)
				rootNode.current.style.setProperty(
					'--image-hover-size',
					`${Math.max(0, stateUpdate.imageSize.height - Math.max(stateUpdate.contentSize, minContentSize))}px`
				);

			if (shouldResize) shouldResize();

			setState(state => ({ ...state, ...stateUpdate }));
			debounce.current.resolve(true);
			debounce.current = null;
		});
	});
	const [debouncedResize] = useState(() => () => {
		if (debounce.current) return debounce.current.promise;
		debounce.current = {};
		debounce.current.promise = new Promise(resolve => {
			debounce.current.resolve = resolve;
		});
		setTimeout(doResize, 16);
		return debounce.current.promise;
	});

	useEffect(() => {
		const resize = () => {
			debouncedResize();
		};
		window.addEventListener('resize', resize);
		return () => {
			window.removeEventListener('resize', resize);
		};
	}, []);

	useEffect(() => {
		if (resized)
			resized
				.then(value => {
					if (value) {
						debouncedResize();
					}
				})
				.catch(() => { });
	}, [resized]);

	useEffect(() => {
		console.log('rendered state', state);
	}, [state]);

	let SquareContainer = square ? "div" : Fragment;
	let SquareProps = square ? { className: classes.squareInner } : {};

	return (
		<div
			ref={gotRoot}
			className={clsx(classes.base, classes.root, {
				[classes.textPositionTop]: textVerticalPosition == 'top',
				[classes.textPositionMiddle]: textVerticalPosition == 'middle',
				[classes.textPositionBottom]: textVerticalPosition == 'bottom',
				[classes.square]: square,
				[classes.withIcon]: (textVerticalPosition != 'middle' && withIcon)
			})}
			{...(clickable && lp ? { component: ButtonBase, ...lp } : {})}
			style={{
				minHeight,
				'--content-size': state.contentSize ? `${state.contentSize}px` : void 0,
				'--content-adjust': state.contentAdjust ? `${state.contentAdjust}px` : '0px',
				'--hover-space':
					state.contentSize && minContentSize && minContentSize > state.contentSize
						? `${0.5 * (minContentSize - state.contentSize)}px`
						: void 0,
				'--image-full-size': state.imageSize ? `${state.imageSize.height}px` : void 0,
				'--image-hover-size':
					state.imageSize && state.contentSize
						? `${Math.max(0, state.imageSize.height - Math.max(state.contentSize, minContentSize))}px`
						: void 0,
			} as CSSProperties}
			onMouseEnter={debouncedResize}
		>
			{focusBackground && <span className={classes.focusHighlight}/>}
			{stripe && <Typography variant={stripeVariant} color={stripeColor} component="div" className={classes.tileStripe}>{stripe}</Typography>}
			<SquareContainer {...SquareProps}>
				<div className={clsx(classes.inner)}>
					{textVerticalPosition != 'middle' && withIcon && (
						<img
							src={image}
							alt={imageAlt}
							ref={gotImage}
							onLoad={imageLoaded}
							className={clsx(classes.imageSizer, {
								[classes.alignLeft]: align == 'left',
								[classes.alignRight]: align == 'right',
								[classes.alignCenter]: align == 'center',
							})}
						/>
					)}
					{textVerticalPosition == 'top' && (
						<>
							{caption && <Typography align={align} variant={captionVariant} color={captionColor} className={classes.caption}>
								{caption}
							</Typography>}
							<Typography align={align} variant={titleVariant} color={titleColor} className={classes.title}>
								<TitleText content={title} decoration={classes.decoration}/>
							</Typography>
						</>
					)}
					{textVerticalPosition != 'middle' && withIcon && (
						<div className={classes.imageWrap}>
							<img
								src={image}
								alt={imageAlt}
								className={clsx(classes.image, {
									[classes.alignLeft]: align == 'left',
									[classes.alignRight]: align == 'right',
									[classes.alignCenter]: align == 'center',
								})}
							/>
						</div>
					)}
					{textVerticalPosition != 'top' && (
						<>
							{caption && <Typography align={align} variant={captionVariant} color={captionColor} className={classes.caption}>
								{caption}
							</Typography>}
							<Typography align={align} variant={titleVariant} color={titleColor} className={classes.title}>
								<TitleText content={title} decoration={classes.decoration}/>
							</Typography>
						</>
					)}
				</div>
				<div className={classes.content} ref={contentReady}>
					<Typography align={align} variant={contentVariant} color={contentColor} className={classes.contentText}>
						{contentText}
					</Typography>
					{!clickable && lp && 'href' in lp && lp.href && (
						<Button
							className={clsx(classes.button, {
								[classes.alignLeft]: align == 'left',
								[classes.alignRight]: align == 'right',
								[classes.alignCenter]: align == 'center',
							})}
							variant={buttonVariant}
							color={buttonColor}
							size={buttonSize}
							fullWidth={buttonFullWidth}
							{...lp}
						>
							<Typography variant={buttonTextVariant} color="inherit">
								{buttonText}
							</Typography>
						</Button>
					)}
				</div>
			</SquareContainer>
		</div>
	);
};

export default ThemeLoader(
	StyleLoader(
		TileBlock,
		makeStyles(
			theme => ({
				base: {
					position: 'relative',
					overflow: 'hidden',
					alignSelf: 'stretch',
					paddingBottom: 0,
					paddingTop: 0,
					'--content-size': '3.75rem',
					'--hover-space': '0px',
					'--image-full-size': '350px',
					'--content-adjust': '0px',
					'--image-hover-size': 'calc(var(--image-full-size, 350px) - var(--content-size, 3.75rem))',
					[theme.breakpoints.only('xs')]: {
						'--image-full-size': '175px',
					},
				},
				contentText: {
					whiteSpace: 'pre-wrap',
					marginLeft: theme.spacing(3),
					marginRight: theme.spacing(3)
				},
				inner: {
					position: 'relative',
					transition: theme.transitions.create('all')
				},
				button: {
					display: 'block',
					marginTop: theme.spacing(1),
				},
				square: {
					'&:before': {
						paddingTop: '100%',
						display: 'block',
						content: '""'
					}
				},
				focusHighlight: {
					overflow: 'hidden',
					pointerEvents: 'none',
					position: 'absolute',
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
					borderRadius: 'inherit',
					opacity: 0,
					backgroundColor: 'currentColor',
					transition: theme.transitions.create('opacity', {
						duration: theme.transitions.duration.short
					})
				},
				squareInner: {
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0
				},
				textPositionTop: {
					'&$square $squareInner': {
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start'
					},
					'&:not(a):hover, &$link': {
						'& $image': {
							height: 'calc(var(--image-hover-size, auto) + var(--content-adjust, auto))',
						},
						'& $content': {
							transform:
								'translate(-50%, calc(-1 * (var(--hover-space, 0px) + var(--content-size, auto) - var(--content-adjust, auto))))',
						},
						paddingBottom:
							'calc(var(--hover-space, 0px) + var(--content-size, auto) - var(--content-adjust, auto))',
						paddingTop: 'var(--hover-space, 0px)',
					},
				},
				textPositionMiddle: {
					'&$square $squareInner': {
						display: 'flex',
						flexDirection: 'column',
						'&:before, &:after': {
							content: '""',
							display: 'block',
							flex: '1 1 auto'
						}
					},
					'&:not($square), &$square:not(a)': {
						display: 'flex',
						flexDirection: 'column',
						'--content-size': '0px',
						'&:before, &:after': {
							content: '""',
							display: 'block',
							flex: '1 1 auto'
						},
						'& $content': {
							position: 'static',
							overflow: 'hidden',
							height: '0px'
						},
						'&:hover $content': {
							height: 'var(--content-size)'
						}
					}
				},
				textPositionBottom: {
					'&:not(a):hover, &$link': {
						'& $image': {
							height: 'calc(var(--image-hover-size, auto) + var(--content-adjust, auto))',
						},
						'& $content': {
							transform:
								'translate(-50%, calc(-1 * (var(--hover-space, 0px) + var(--content-size, auto) - var(--content-adjust, auto))))',
						},
						paddingBottom:
							'calc(var(--hover-space, 0px) + var(--content-size, auto) - var(--content-adjust, auto))',
						paddingTop: 'var(--hover-space, 0px)',
					},
					'&$square $squareInner': {
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-end'
					},
					'&:not($withIcon):not(a):hover $inner': {
						transform: 'translate(-50%, calc(-1 * var(--content-size, auto)))'
					}
				},
				root: {
					transition: theme.transitions.create('all'),
					display: 'block',
					textDecoration: 'none',
					'a&': {
						paddingTop: 0,
						paddingBottom: 0,
						'& $content': {
							transform: 'none',
							position: 'static',
							'& $contentText': {
								marginLeft: 0,
								marginRight: 0
							}
						}
					},
					'& $image': {
						height: 'var(--image-full-size, auto)',
						transition: theme.transitions.create('all'),
					},
					'& $content': {
						transition: theme.transitions.create('all'),
						transform: 'none',
					},
					'&:hover $focusHighlight': {
						opacity: theme.palette.action.hoverOpacity
					}
				},
				backgroundImage: {},
				link: {
					display: 'block',
					textDecoration: 'none',
					color: 'inherit',
				},
				image: { display: 'block', maxWidth: '100%' },
				imageWrap: {},
				alignLeft: { marginRight: 'auto' },
				alignRight: { marginLeft: 'auto' },
				alignCenter: { marginLeft: 'auto', marginRight: 'auto' },
				title: {},
				withIcon: {},
				content: {
					position: 'absolute',
					top: '100%',
					left: 0,
					right: 0,
					display: 'flex',
					flexDirection: 'column',
				},
				imageSizer: {
					display: 'block',
					maxWidth: '100%',
					position: 'absolute',
					top: 0,
					left: 0,
					opacity: 0,
					zIndex: -1,
				},
				fixed: {
					height: 'var(--image-full-size) !important',
				},
				decoration: {},
				tileStripe: {
					top: 18,
					right: -22,
					position: 'absolute',
					transform: 'rotate(45deg)',
					width: 100,
					textAlign: 'center',
					transformOrigin: '50% 50%'
				}
			}),
			{ name: 'SwTile' }
		)
	)
);
