let debouncedFunctionId = 1;

const Maybe = Symbol();
const Now = Symbol();

export type DebouncedFunction = {
    (arg?: typeof Maybe | typeof Now): void;
    MAYBE: typeof Maybe;
    NOW: typeof Now;
}

export default function debounce(method: () => void, duration = 16): DebouncedFunction {
    let timers = new Map<object, ReturnType<typeof setTimeout>>();
    let globalTimer: ReturnType<typeof setTimeout> = null;

    var rv: DebouncedFunction = function(now?: typeof Maybe | typeof Now) {
        if (now === Maybe) {
            if (this) {
                if (timers.has(this)) return;
            } else {
                if (globalTimer) return;
            }
        }

        if (now === Now) {
            method.call(this);
            
            return;
        }

        if (this) {
            if (timers.has(this)) {
                clearTimeout(timers.get(this));
                timers.delete(this);
            } else {
                if (globalTimer) clearTimeout(globalTimer);
                globalTimer = null;
            }
        }

        let ttimer = setTimeout(() => {
            if (this) {
                timers.delete(this);
            } else {
                globalTimer = null;
            }
            method.call(this);
        }, duration);

        if (this) {
            timers.set(this, ttimer);
        } else {
            globalTimer = ttimer;
        }
    } as DebouncedFunction;

    rv.MAYBE = Maybe;
    rv.NOW = Now;
    return rv;
}