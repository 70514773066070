import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import getChildren, { flattenChildren, emptyChildren, appendChildren } from '../../utils/children';
import { Context as PaginatorContext } from '../paginator-container/context';

import type PaginatorProps from './props';

const PaginatorBlock = (props: PaginatorProps, ref?: React.Ref<any>): JSX.Element => {
    let contentBlocks = flattenChildren(props.content);
    let context = useContext(PaginatorContext);
    useEffect(() => {
        context.setItemCount(contentBlocks.length);
    }, [contentBlocks.length]);
    let cbs = contentBlocks.slice(context.page * context.itemsPerPage, (context.page + 1) * context.itemsPerPage);
    
    return <>{getChildren(cbs)}</>
}

export default React.forwardRef(PaginatorBlock);