import { FakeEvent, FakeEventTarget, FakeEventInit, ListenerChangeEvent } from './FakeEvent';

let MQLPrototype: MediaQueryList;

if (window.matchMedia) {
	let mq = window.matchMedia('(min-width: 1px)');
	if (!mq.addEventListener) {
		MQLPrototype = (mq as any).__proto__ as MediaQueryList;
	}
} else {
	if (typeof MediaQueryList != 'undefined' && !MediaQueryList.prototype.addEventListener) {
		MQLPrototype = MediaQueryList.prototype;
	}
}

if (MQLPrototype) {
        let matchMedia = window.matchMedia;
        window.matchMedia = function(query: string): MediaQueryList {
                interface MediaQueryListEventInit extends FakeEventInit {
                        matches: boolean;
                        media: string;
                }
                class MediaQueryListEvent extends FakeEvent {
                        readonly matches: boolean;
                        readonly media: string;

                        constructor(type: string, init: MediaQueryListEventInit) {
                                super(type, init);
                                this.matches = init.matches;
                                this.media = init.media;
                        }
                }
                window.matchMedia = function(query: string): MediaQueryList {
                        let r: MediaQueryList = matchMedia.call(window, query);
                        // @ts-ignore
                        FakeEventTarget.mixin(r);
                        let lc = 0;
                        let changeHandler = () => {
                                r.dispatchEvent(new MediaQueryListEvent('change', { matches: r.matches, media: query }))
                        }
                        r.addEventListener('removeListener', (event: ListenerChangeEvent) => {
                                if (event.name == 'change') {
                                        lc--;
                                        if (!lc) {
                                                r.removeListener(changeHandler);
                                        }
                                }
                        });
                        r.addEventListener('addListener', (event: ListenerChangeEvent) => {
                                if (event.name == 'change') {
                                        if (!lc) {
                                                r.addListener(changeHandler);
                                        }
                                        lc++;
                                }
                        });
                        return r;
                }
                return window.matchMedia(query);
        }
}

